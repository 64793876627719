import React, { useEffect } from 'react'
import { useUser } from './UserContext'
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const {setrole}=useUser();
    const navigate = useNavigate();

    useEffect(() => {
      setrole('');
      sessionStorage.setItem('role','');
      navigate("/");
    }, [])
    
    
  return (
    <div></div>
  )
}

export default Logout