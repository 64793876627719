import React from 'react'
import {  useNavigate } from 'react-router-dom';
import { MdOutlineMail } from "react-icons/md";
import { FaFacebookF, FaTiktok, FaLinkedin, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa6";

const Footer = () => {
  const navigate = useNavigate();
  const open=(link)=>{
    window.open(link,'_blank');
  }
  return (
    <footer className='relative bottom-0 left-0 bg-[#073B4C] items-center justify-center text-[11px] min-w-max min-h-[350px] min-[426px]:text-xl  ml-[-20px]   overflowFooter '>
      <div className='min-[420px]:ml-[-40px] min-[321px]:ml-[-80px] min-[348px]:ml-[-70px] flex flex-col pt-[50px] ml-[-90px] min-w-min justify-around px-32 bg-[#073B4C] min-h-[50px] items-center text-white  '>
      <button className='max-[420px]:text-xl mb-[20px] mt-[-30px] bg-red-500 text-white hover:bg-red-700 transition duration-150 ease-in-out rounded-sm font-bold p-1' onClick={()=>open(`https://www.tcsexpress.com/track`)} >Track Your Order</button>
        <div className='flex flex-row  space-x-10'>
          <div className='opacity-90 flex flex-row space-x-2'>
            <MdOutlineMail className='text-yellow-300 text-xl cursor-pointer 'onClick={()=>open('mailto:minitronixs@gmail.com')} />
            <a className='cursor-pointer' onClick={()=>open('mailto:minitronixs@gmail.com')}>minitronixs@gmail.com</a>
          </div>
          <div className='opacity-90 flex flex-row'>
            <FaWhatsapp className='text-yellow-300 text-xl cursor-pointer'  onClick={()=>open('https://wa.me/923455174586')} />
            <a className='cursor-pointer' onClick={()=>open('https://wa.me/923455174586')}>+92 345 5174586</a>
          </div>
        </div>

        <div className='flex flex-row  space-x-9 mt-[50px] text-base'>

          <FaFacebookF className='opacity-70 hover:cursor-pointer text-2xl hover:opacity-100 hover:text-[yellow]  transition-all' onClick={() =>open('https://www.facebook.com/people/Minitonix/61565706076399/?mibextid=LQQJ4d')} />
          <FaTiktok className='opacity-70 hover:cursor-pointer text-2xl hover:opacity-100 hover:text-[yellow] transition-all' onClick={() =>open('https://www.tiktok.com/@minitronixs?lang=en')} />
          <FaYoutube className='opacity-70 hover:cursor-pointer text-2xl hover:opacity-100 hover:text-[yellow] transition-all' onClick={() =>open('https://www.youtube.com/@minitronixs')} />
          <FaInstagram className='opacity-70 hover:cursor-pointer text-2xl hover:opacity-100 hover:text-[yellow] transition-all' onClick={() =>open('https://www.instagram.com/minitronixs/?utm_source=ig_web_button_share_sheet')} />
          <FaLinkedin className='opacity-70 hover:cursor-pointer text-2xl hover:opacity-100 hover:text-[yellow] transition-all' onClick={() =>open('https://www.linkedin.com/in/minitronix-minitronix-13510432a/')} />
        </div>

        <div className='flex flex-row  space-x-7 mt-[50px] text-base'>
          <a
            className='opacity-70 hover:cursor-pointer text-[12px] hover:opacity-100 hover:text-[yellow] transition-all'
            href='/privacy'
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy Policy
          </a>
          <a
            className='opacity-70 hover:cursor-pointer text-[12px] hover:opacity-100 hover:text-[yellow] transition-all'
            href='/return'
            target='_blank'
            rel='noopener noreferrer'
          >
            Return Policy
          </a>
          <a
            className='opacity-70 hover:cursor-pointer text-[12px] hover:opacity-100 hover:text-[yellow] transition-all'
            href='/terms'
            target='_blank'
            rel='noopener noreferrer'
          >
            Terms & Conditions
          </a>
        </div>
        

        <div className='mt-[50px] opacity-70'>
          Copyright © 2024 Minitronix - All Rights Reserved
        </div>
      </div>
    </footer>
  )
}

export default Footer