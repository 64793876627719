import React, { useEffect } from 'react';
import { useUser } from './UserContext';
import { useNavigate } from 'react-router-dom';

const ProtectedAdmin = (props) => {
  const { Component, openCart } = props;
  const { role, setrole } = useUser(); 
  const navigate = useNavigate();

  useEffect(() => {
    
    const storedRole = sessionStorage.getItem('role');
    
    if (storedRole === "admin") {
      setrole(storedRole);
    } else if (role !== "admin") {
      navigate('/login');
    }
  }, [role, navigate, setrole]);

  return (
    <div>
      <Component openCart={openCart} />
    </div>
  );
};

export default ProtectedAdmin;
