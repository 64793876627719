import React, { useState } from 'react';
import { FaWhatsapp } from "react-icons/fa6";
import { PiShoppingCartSimple } from "react-icons/pi";
import { UserProvider } from "./Components/Authentication/UserContext";
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Home/Home";
import CartItems from "./Components/Home/CartItems";
import Login from './Components/Authentication/Login';
import ProtectedAdmin from "./Components/Authentication/ProtectedAdmin";
import Admin from "./Components/Home/Admin";
import Product from "./Components/Home/Product";
import PrivacyPolicy from "./Components/Footer/PrivacyPolicy";
import ReturnPolicy from "./Components/Footer/ReturnPolicy";
import TermsAndConditions from "./Components/Footer/TermsAndConditions";
import About from "./Components/Home/About";
import ItemDet from "./Components/Home/ItemDet";
import Contact from "./Components/Home/Contact";
import Logout from "./Components/Authentication/Logout";
import Payment from './Components/Home/Payment';

function App() {
  const path =useLocation();
  const [isCartOpen, setCartOpen] = useState(false);

  const openCart = () => {
    setCartOpen(true);
    document.body.style.overflow = 'hidden'; // Disable scrolling
  };

  const closeCart = () => {
    setCartOpen(false);
    document.body.style.overflow = 'auto'; // Re-enable scrolling
  };

  const open = (link) => {
    window.open(link, '_blank');
  };

  return (
    <UserProvider>
        {
          path.pathname!=='/admin' && (
          <>
          <FaWhatsapp
          className="max-[425px]:text-[50px] max-[378px]:text-[55px] fixed bottom-6 right-5 text-[60px] font-extrabold p-4 z-30 bg-[#25D366] text-[#FFFF] rounded-[50%] hover:cursor-pointer"
          onClick={() => open('https://wa.me/923455174586')}
        />
        <PiShoppingCartSimple
          className="max-[425px]:text-[50px] max-[378px]:text-[55px] fixed bottom-24 right-5 text-[65px] font-extrabold p-4 z-30 hover:text-[#363232] bg-[#e0dada] rounded-[50%] hover:cursor-pointer"
          onClick={openCart}
        />
          </>)
        }
        

        {/* Main content with dimmed overlay when cart is open */}
        <div className={`relative ${isCartOpen ? 'opacity-50 pointer-events-none' : ''} overflowFooter`}>
          <Header />
          <Routes>
            <Route path="/" element={<Home openCart={openCart}/>} />
            <Route path="/login" element={<Login />} />
            <Route path="/product" element={<Product openCart={openCart}/>} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/return" element={<ReturnPolicy />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/payment" element={<Payment openCart={openCart}/>} />
            <Route path="/itemdet" element={<ItemDet openCart={openCart}/>} />
            <Route path="/admin" element={<ProtectedAdmin Component={Admin} openCart={openCart} />} />
            <Route path="/logout" element={<ProtectedAdmin Component={Logout} />} />
          </Routes>
          <Footer />
        </div>

        {/* Cart Items */}
        {isCartOpen && (
          <div>
            {/* Cart sliding in from the right */}
            <CartItems closeCart={closeCart}/>
          </div>
        )}
    </UserProvider>
  );
}

export default App;
