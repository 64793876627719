import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../Authentication/UserContext';
import axios from 'axios'
import BaseUrl from '../../BaseUrl'
import ComingSoon from '../../Images/ComingSoon.jpg'
import Loader from '../../Images/loader.gif'

import KitsHoverImg from '../../Images/Kits-Hover-Img.jpg'

const Product = ({ openCart }) => {
    const { setCart, cart } = useUser();

    const navigate = useNavigate();
    const [isAnimating, setIsAnimating] = useState(false);
    const [hoveredItem, setHovered] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [itemsRes, setitemRes] = useState([]);
    const ItemDetPage = (obj) => {
        navigate("/itemdet", { state: { person: obj } });
    }
    const AddCartItems = (item) => {
        const itemExists = cart.some(cartItem => cartItem._id === item._id);

        if (!itemExists) {
            setCart([...cart, {
                _id: item._id,
                name: item.name,
                price: item.price,
                picture: item.picture,
                originalQuantity: item.quantity,
                quantity: 1,
            }]);
            localStorage.setItem('CartItems',JSON.stringify(cart));
            openCart();
        }
        else {
            setCart(cart.map(cartItem => 
                cartItem._id === item._id 
                    ? { ...cartItem, quantity: cartItem.quantity + 1 } 
                    : cartItem
            ));
            localStorage.setItem('CartItems',JSON.stringify(cart));
            openCart();
        }

    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const itemsRes = await axios.get(`${BaseUrl}/items`);
                setitemRes(itemsRes.data.message);
                console.log(itemsRes);
                setIsLoading(false);

            } catch (err) {
                console.log(err);
                setIsLoading(false);
            }
        };

        setIsAnimating(true);
        fetchData();
    }, []);
    return (
        <div className={`${isAnimating ? 'animate-fadeUp' : ''} ` }>
            <h1 className={`min-[426px]:text-2xl max-[426px]:mt-[100px]  min-[769px]:ml-[50px] min-[1025px]:ml-[20px] min-[1025px]:text-4xl min-[1081px]:text-5xl min-[1081px]:pt-[50px] min-[1081px]:ml-[45px] text-xl text-[#192A3D]  h-[74px] pt-[20px] text-center font-extrabold font-serif  ${isAnimating ? 'animate-slideInTop' : ''}  `}>Latest <span className='text-[blue]'>Products</span></h1>
            <h1 className={`min-[426px]:text-[13px]  min-[769px]:ml-[50px] min-[1025px]:ml-[20px] min-[1025px]:text-[15px]  min-[1081px]:pt-[50px] min-[1081px]:ml-[45px] text-[12px] text-[#333436]  h-[74px] pt-[0px] text-center font-light ${isAnimating ? 'animate-slideInTop' : ''}  `}>"Innovating the future, one circuit at a time. Empowering tomorrow with the precision of robotics and the brilliance of electronics." <br /><span className='text-[blue]'>— Minitronix</span></h1>
            <section className={` min-[1080px]:mt-[100px]  w-[100%]  min-[1025px]:min-h-[37rem]  min-[1025px]:grid min-[1025px]:grid-cols-3  min-[712px]:space-x-4 min-[1025px]:object-cover  justify-center space-x-0   flex flex-wrap   ${isAnimating ? 'animate-slideInBottom' : ''} `}>
                {
                    isLoading ?
                        <img className=' max-w-[150px] max-h-[150px] ' src={Loader} alt='loading' /> :
                        itemsRes.length > 0 ?
                            itemsRes.map((item, index) => (
                                <div key={index} className=' min-[1025px]:text-[18px] min-[1081px]:w-[550px]   min-[1081px]:ml-[20px] pl-[18px] w-[350px]  mb-[60px] '>
                                    {
                                        item.picture ? <img className=' min-[1025px]:h-[320px] min-[1025px]:w-[450px] min-[1081px]:w-[320px] min-[1100px]:w-[400px] min-[1300px]:w-[450px]  rounded-[5%] hover:transform hover:-translate-y-3 transition-all duration-1000 ease-in-out hover:cursor-pointer'
                                            src={hoveredItem === index ? `${item.picture2}` : `${item.picture}`}
                                            alt={item.name}
                                            onClick={() => ItemDetPage(item)}
                                            onMouseEnter={() => setHovered(index)}
                                            onMouseLeave={() => setHovered(null)} /> : 'No Image'
                                    }
                                    <h1 className=' text-black font-bold w-full  my-[20px] hover:text-red-600 hover:cursor-pointer' onClick={() => ItemDetPage(item)}>{item.name || 'none'}</h1>
                                    <h2 className=' text-blue-700 font-bold  w-full  my-[20px]'>₨ {item.price || 'none'}    <span className='min-[1025px]:text-[14px] text-[12px] opacity-80  font-medium line-through '>₨ {parseInt(item.price) + (parseInt(item.price) * 0.2) || 'none'}</span></h2>
                                    {
                                        parseInt(item.quantity) !== 0 ?
                                            <button className=' bg-blue-700 rounded-[5%] w-[120px] h-[40px] text-white hover:bg-red-500 hover:cursor-pointer' onClick={() => AddCartItems(item)} >Add to cart</button> :
                                            <button className=' bg-red-500 rounded-[5%] w-[120px] h-[40px] text-white hover:bg-blue-500 hover:cursor-pointer'  >Out Of Stock</button>
                                    }

                                </div>
                            )) :
                            <h1 className='min-[426px]:text-xl min-[769px]:text-start min-[769px]:ml-[50px] min-[769px]:text-2xl min-[1025px]:ml-[20px] min-[1081px]:text-3xl min-[1081px]:pt-[50px] min-[1081px]:ml-[45px] text-[18px] text-[#192A3D]  h-[54px] text-center  font-serif'>No Item Found</h1>
                }
                {
                    itemsRes.length > 0 ?
                        <>
                            <div className='min-[1025px]:text-[18px] min-[1081px]:w-[550px] opacity-50 min-[1081px]:ml-[20px] pl-[18px] w-[350px]  mb-[60px] hover:cursor-not-allowed '>
                                <img className=' min-[1025px]:h-[320px] min-[1025px]:w-[450px] min-[1081px]:w-[320px] min-[1100px]:w-[400px] min-[1300px]:w-[450px]  rounded-[5%] hover:transform hover:-translate-y-3 transition-all duration-500' src={ComingSoon} alt='coming Soon' />
                                <h1 className='text-black font-bold w-full  my-[20px]  '>Mini-Techstarter</h1>
                                <h2 className='text-blue-700 font-bold  w-full  my-[20px]'>₨ 5500  <span className='min-[1025px]:text-[14px] text-[12px] opacity-80  font-medium line-through '>₨ 6600</span></h2>
                                <button className='bg-blue-700 rounded-[5%] w-[120px] h-[40px] text-white hover:cursor-not-allowed '>Add to cart</button>
                            </div>
                        </> : ''
                }
            </section>
        </div>

    )
}

export default Product