import React ,{useState , useEffect} from "react";

const TermsAndConditions = () => {
  const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        setIsAnimating(true);
    }, []);

  return (
    <div className={`max-[600px]:mx-0 max-[426px]:px-10 max-[426px]:mt-[100px]  container mx-8 p-6 px-28 my-8 font-serif ${isAnimating? 'animate-slideInRight':''}`}>
      <p className="text-[#524c4c] leading-9">Welcome to Minitronix <mark>www.minitronix.org</mark> ! By accessing this website, you agree to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Minitronix's relationship with you in relation to this website.</p>

      <h2 className="max-[600px]:text-3xl text-4xl font-semibold my-5 text-[#2e2b2b]">Cookies</h2>
      <p className=" text-[#524c4c] leading-9">We employ the use of cookies. By accessing Minitronix, you agreed to use cookies in agreement with Minitronix's Privacy Policy.</p>

      <h2 className="max-[600px]:text-3xl text-4xl font-semibold my-5 text-[#2e2b2b]">License</h2>
      <p className="text-[#524c4c] leading-9">Unless otherwise stated, Minitronix and/or its licensors own the intellectual property rights for all material on www.minitronix.org. All intellectual property rights are reserved.</p>

      <h2 className="max-[600px]:text-3xl text-4xl font-semibold my-5 text-[#2e2b2b]">Hyperlinking to our Content</h2>
      <p className="text-[#524c4c] leading-9">Organizations may link to our website without prior written approval if the link: (a) is not deceptive; (b) does not falsely imply sponsorship...</p>

      <h2 className="max-[600px]:text-3xl text-4xl font-semibold my-5 text-[#2e2b2b]">Disclaimer</h2>
      <p className="text-[#524c4c] leading-9">The information on this website is provided "as is" without any representations or warranties...</p>
    </div>
  );
};

export default TermsAndConditions;
