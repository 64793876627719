import React, { useState, useEffect } from 'react';
import { useUser } from '../Authentication/UserContext';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import Countries from './Countries'

const Payment = () => {
    const [selectedCountry, setSelectedCountry] = useState(Countries[0]);
    const getStoredData = (key, defaultValue) => {
        const data = localStorage.getItem(key);
        try {
            return data ? JSON.parse(data) : defaultValue;
        } catch (error) {
            console.error(`Error parsing ${key}:`, error);
            return defaultValue;
        }
    };

    const storedCart = getStoredData('CartItems', []);
    const ONum = getStoredData('OrderNumber', 0);
    const DelieveryAmount = getStoredData('DAmount', 0);
    console.log(DelieveryAmount);


    const navigate = useNavigate();
    const { cart, setCart ,DAmount ,setDAmount } = useUser();
    const [Total, setTotal] = useState(0);
    const [Length, setLength] = useState(0);

    const [isAnimating, setIsAnimating] = useState(false);

    const [Fname, setFName] = useState('');
    const [Lname, setLName] = useState('');
    const [email, setEmail] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [phone, setPhone] = useState('');
    const [postal, setPostal] = useState('');
    const [country, setCountry] = useState('');

    const [VerifyDetail, setVerifyDetail] = useState(true);

    const [OrderNumber, setOrderNUmber] = useState(0);
    
    useEffect(() => {
        const userDet = getStoredData('userDet', {});
        console.log(userDet); // This will show the parsed object
        setFName(userDet.Fname || '');
        setLName(userDet.Lname || '');
        setEmail(userDet.email || '');
        setPhone(userDet.phone || '');
        setStreetAddress(userDet.streetAddress || '');
        setCity(userDet.city || '');
        setState(userDet.state || '');
        setCountry(userDet.country || '');
        setPostal(userDet.postal || '');
        setSelectedCountry(userDet.countryCode ? Countries.find(c => c.value === userDet.countryCode) : Countries[0]);
    }, []);



    useEffect(() => {
        if (storedCart.length > 0) {
            setCart(storedCart);
        }
        if (ONum.length !== '' && ONum !== null && ONum !== 0) {
            setOrderNUmber(ONum);
        }
        if (DelieveryAmount.length !== '' && ONum !== null && ONum !== 0) {
            setDAmount(DelieveryAmount);
        }
        else {
            const number = Math.floor(Math.random() * 10000);
            setOrderNUmber(number);
        }
        console.log(selectedCountry);
        setCountry(selectedCountry.name);
    }, [selectedCountry]);


    useEffect(() => {
        setIsAnimating(true);
        window.scrollTo(0, 0);
        if (cart.length > 0) {
            const newTotal = cart.reduce((acc, item) => acc + (item.price * item.quantity), 0);
            setTotal(newTotal);
        } else {
            setTotal(0); // Reset total if cart is empty
        }
        setLength(cart.length);



        localStorage.setItem('CartItems', JSON.stringify(cart));
        localStorage.setItem('OrderNumber', JSON.stringify(OrderNumber));
        localStorage.setItem('DeliveryAmount', DAmount);
    }, [cart, OrderNumber]);

    const submitHandler = () => {
        const userDetails = {
            Fname,
            Lname,
            email,
            phone,
            streetAddress,
            city,
            state,
            country,
            postal,
            countryCode: selectedCountry.value,
        };
        localStorage.setItem('userDet', JSON.stringify(userDetails));
        setVerifyDetail(false);
    }
    const goBack = () => {
        setVerifyDetail(true);
    }
    const submitHandler1 = async (event) => {
        event.preventDefault();
        try {

            localStorage.setItem('CartItems', JSON.stringify([]));
            localStorage.setItem('OrderNumber', JSON.stringify(0));
            setFName( '');
            setLName( '');
            setEmail( '');
            setPhone( '');
            setStreetAddress('');
            setCity ('');
            setState( '');
            setPostal('');
            setCountry( '');
            
            localStorage.setItem('userDet',[]);
            setVerifyDetail(true);
            navigate('/');
            setCart([]);
            setDAmount('');
            setOrderNUmber(0);

            const whatsappNumber = '923455174586';
            const countryCode = '+'
            const productDetails = cart.map(item => {
                return `%0A${item.name}%20*%20${item.quantity}%20%20%20%20%20Rs%20${item.price * item.quantity}`;
            }).join('');
            const personalDet = `%0A%0A*Personal Details* %0AName: ${Fname}%20 ${Lname} %0AEmail: ${email}%0APhone:%2B${selectedCountry.value}${phone}%0AAddress: ${streetAddress}%20 ${city}%20${state}%20${country} %0APostal Code:${postal}`;
            const orderDet = `%0A%0A*Order Details*` +
                `${productDetails}%0A%0A*Total*%20%20Rs%20${Total}` +
                `%0A*Shipping%20Fee:*%20Rs%20${parseInt(DAmount)}` +
                `%0A*SubTotal:*%20Rs%20${Total + parseInt(DAmount)}`;
            const message = `*Order Number:%20MTU-${OrderNumber}*` + personalDet + orderDet;

            const whatsappUrl = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${message}`;
            window.open(whatsappUrl, '_blank');

        } catch (error) {
            console.error('Error during Sending Data:', error);
        }
    };

    return (
        <div className={` max-[426px]:mt-[80px] ${isAnimating ? 'animate-fadeUp' : ''} `}>
            <section className={` max-[426px]:flex-col-reverse min-[1025px]:min-h-[37rem] min-[640px]:flex-row flex flex-col  flex-wrap px-[40px] min-h-[29rem] font-serif   ${isAnimating ? 'animate-fadeIn' : ''} `}>
                <div className={` w-[100%] min-[640px]:w-[50%] flex flex-col mb-[20px]    ${isAnimating ? 'animate-slideInLeft' : ''}`}>
                    <h1 className='min-[1025px]:text-4xl text-3xl font-bold mt-[50px] space-x-2'>Billing Details</h1>
                    {
                        VerifyDetail ?
                            <form onSubmit={submitHandler} className='flex flex-col mr-[20px] mt-[30px]'>
                                <div>
                                    <input
                                        className=' max-[426px]:text-[18px] text-xl  m-3 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg'
                                        type='text'
                                        placeholder='Enter First Name'
                                        required
                                        value={Fname}
                                        onChange={(e) => setFName(e.target.value)}
                                    />
                                    <input
                                        className=' max-[426px]:text-[18px] text-xl  m-3 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg'
                                        type='text'
                                        placeholder='Enter Last Name'
                                        required
                                        value={Lname}
                                        onChange={(e) => setLName(e.target.value)}
                                    />
                                </div>
                                <input
                                    type='email'
                                    className='max-[426px]:text-[18px]  text-xl m-3 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg'

                                    placeholder='Enter Email(optional)'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <div className='min-[451px]:flex'>
                                    <Select
                                        className='min-[640px]:max-w-[30%]  min-[451px]:mt-[10px] max-w-[100%]' // Adjust the width of Select
                                        options={Countries}
                                        value={selectedCountry}
                                        onChange={setSelectedCountry}
                                        placeholder="Select Country Code"

                                    />
                                    <input
                                        type='number'
                                        className='max-[426px]:text-[18px]  text-xl m-3 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg'
                                        placeholder='Enter WhatsApp Number'
                                        required
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </div>
                                <input
                                    type='text'
                                    className='max-[426px]:text-[18px] text-xl m-3 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg'
                                    placeholder='Street Address'
                                    required
                                    value={streetAddress}
                                    onChange={(e) => setStreetAddress(e.target.value)}
                                />

                                <div>
                                    <input
                                        type='text'
                                        className='max-[426px]:text-[18px] text-xl m-3 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg'
                                        placeholder='City'
                                        required
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                    />

                                    <input
                                        type='text'
                                        className='max-[426px]:text-[18px] text-xl m-3 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg'
                                        placeholder='State'
                                        required
                                        value={state}
                                        onChange={(e) => setState(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <input
                                        type='text'
                                        className='max-[426px]:text-[18px]  text-xl m-3 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg'
                                        placeholder='Country'
                                        required
                                        value={country}
                                        onChange={(e) => setCountry(e.target.value)}
                                        readOnly
                                    />
                                    <input
                                        type='text'
                                        className='max-[426px]:text-[18px]  text-xl m-3 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg'
                                        placeholder='Postal Code'
                                        required
                                        value={postal}
                                        onChange={(e) => setPostal(e.target.value)}
                                    />
                                </div>

                                <button type='submit' className='bg-blue-700  text-white min-w-[130px] min-h-[35px] flex justify-center items-center rounded-lg cursor-pointer hover:bg-blue-500 mt-[40px]' >Next</button>
                            </form> :
                            <div className='flex flex-col mr-[20px] mt-[30px]'>
                                <div className='flex'>
                                    <h2
                                        className=' max-[426px]:text-[18px] text-xl  m-3 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg'
                                    >{Fname}</h2>
                                    <h2
                                        className=' max-[426px]:text-[18px] text-xl  m-3 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg'
                                    >{Lname}</h2>
                                </div>
                                <h2
                                    className='max-[426px]:text-[18px]  text-xl m-3 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg'
                                >{email}</h2>
                                <div className='w-full max-w-md   flex flex-row  '>
                                    <h2
                                        className='w-[40%]  ml-[10px] text-xl float-right p-2  focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg'
                                    >{selectedCountry.value}{phone}</h2>
                                </div>
                                <h2
                                    className='max-[426px]:text-[18px] text-xl m-3 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg'
                                >{streetAddress}</h2>

                                <div className='flex'>
                                    <h2
                                        className='max-[426px]:text-[18px] text-xl m-3 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg'
                                    >{city}</h2>

                                    <h2
                                        className='max-[426px]:text-[18px] text-xl m-3 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg'
                                    >{state}</h2>
                                </div>
                                <div className='flex'>
                                    <h2
                                        className='max-[426px]:text-[18px]  text-xl m-3 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg'
                                    >{country}</h2>
                                    <h2
                                        className='max-[426px]:text-[18px]  text-xl m-3 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg'
                                    >{postal}</h2>
                                </div>

                                <div className='flex'>
                                    <button className='bg-blue-700  text-white min-w-[50%] min-h-[35px] flex justify-center items-center rounded-lg cursor-pointer hover:bg-blue-500 mt-[40px]' onClick={submitHandler1} >Place Order</button>
                                    <button className='bg-red-700  text-white min-w-[50%] min-h-[35px] flex justify-center items-center rounded-lg cursor-pointer hover:bg-blue-500 mt-[40px]' onClick={goBack} >GO Back</button>
                                </div>
                            </div>
                    }
                </div>
                <div className={` w-[100%] min-[640px]:w-[50%] flex flex-col font-roboto my-[20px]   ${isAnimating ? 'animate-slideInRight' : ''}`}>
                    <h1 className='min-[1025px]:text-2xl text-xl font-bold my-[30px] space-x-2'>Your Order MTU-{OrderNumber}</h1>
                    <h1 className='min-[1025px]:text-[18px] text-[16px] font-bold mt-[-10px] mb-[30px] text-[#3e3939] space-x-2'>Delievered in 3-5 Days</h1>
                    <div className='max-[426px]:text-[16px]  min-h-[50px] text-[20px] mb-[-15px] flex flex-row   justify-between items-center'>
                        <p className='font-bold   '>Product</p>
                        <p className='font-bold  '>Total</p>
                    </div>
                    <div className=' min-h-[60px] my-[10px]'>
                        <div>
                            {
                                cart.length > 0 ? cart.map((item, index) => (
                                    <div key={index} className='max-[426px]:text-[16px] min-h-[60px] flex flex-col my-[10px]   '>
                                        <div className=' max-[426px]:text-[16px] max-h-[60px] w-[100%] text-[18px] mt-[5px] flex flex-row justify-between '>
                                            <p className=' text-[#24272D]  '>{item.name}<span className='font-semibold'> * {item.quantity}</span></p>
                                            <p className=' text-[#24272D]  '>Rs {parseInt(item.price) * (item.quantity)}</p>
                                        </div>

                                    </div>
                                )) : ''
                            }
                        </div>
                    </div>
                    <div className='max-[426px]:text-[16px] min-h-[50px] flex flex-row justify-between text-[18px]  '>
                        <p className='font-bold   '>Total</p>
                        <p className='font-bold  '>Rs {Total}</p>
                    </div>
                    <div className='max-[426px]:text-[16px] min-h-[50px] flex flex-row justify-between text-[18px]  '>
                        <p className='  '>Shipping Fee</p>
                        <p className=' '>Rs {DAmount}</p>
                    </div>
                    <div className='max-[426px]:text-[16px] min-h-[30px] flex flex-row justify-between text-[18px] '>
                        <p className='font-bold  '>SubTotal</p>
                        <p className='font-bold  bg-red-500 text-white rounded-md pt-[2px]'>Rs {Total + parseInt(DAmount)}</p>
                    </div>
                </div>

            </section>
            <section className=' w-[100%] min-h-[500px] px-[10px] py-[10px]'>
                <div class="bg-[#efebeb] p-6 rounded-lg text-center">
                    <p class="text-lg font-semibold mb-4">Thank you for moving forward with your order. Here are the payment details:</p>

                    <div class="bg-white p-4 rounded-md shadow-md mb-4">
                        <p class="text-md font-medium text-gray-700 mb-2">Bank Transfer Details:</p>
                        <ul class="list-disc list-inside font-roboto">
                            <li><strong>Bank Name:</strong> Meezan Bank- Barakahu</li>
                            <li className='max-[426px]:text-sm max-[375px]:text-[10px] max-[320px]:text-[8px] text-xl'><strong className='text-[16px]'>IBAN Number:</strong>  PK98MEZN0003810109644496</li>
                        </ul>
                    </div>


                    <div class="bg-white p-4 rounded-md shadow-md mb-6">
                        <p class="text-md font-medium text-gray-700 mb-2">EasyPaisa Details:</p>
                        <ul class="list-disc list-inside font-roboto">
                            <li><strong>Name:</strong>MUHAMMAD USMAN</li>
                            <li><strong>Phone Number:</strong> 0315-0546717</li>
                        </ul>
                    </div>

                    <p class="mb-4">Once you've made the payment, please send a screenshot of the transaction confirmation here. We’ll process your order right after.</p>
                </div>

            </section>

        </div>

    )
}

export default Payment