import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [role, setrole] = useState('');
  const [DAmount, setDAmount] = useState('');
  const [cart, setCart] = useState([]);

  return (
    <UserContext.Provider value={{ role, setrole,cart,setCart,DAmount,setDAmount }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
