import React, { useState, useEffect } from 'react';
import {FaWhatsapp } from "react-icons/fa6";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";

const Contact = () => {
    const [isAnimating, setIsAnimating] = useState(false);

    const open=(link)=>{
        window.open(link,'_blank');
      }

    useEffect(() => {
        setIsAnimating(true);
        
    }, []);
    return (
        <div className={`${isAnimating ? 'animate-fadeUp' : ''}`}>
            <h1 className={`min-[426px]:text-2xl max-[426px]:mt-[100px]   min-[769px]:ml-[50px] min-[1025px]:ml-[20px] min-[1025px]:text-4xl min-[1081px]:text-5xl min-[1081px]:pt-[50px] min-[1081px]:ml-[45px] text-2xl text-[#192A3D]  h-[74px] pt-[20px] text-center font-extrabold font-serif  ${isAnimating ? 'animate-slideInTop' : ''}  `}>Reach Out To Us! <span className='text-[blue]'>its EASY</span></h1>
            <section className={` min-[1025px]:mt-[200px]  w-[100%] min-[1025px]:min-h-[26rem]  min-[1025px]:grid min-[1025px]:grid-cols-3 min-[1025px]:object-cover  min-[1025px]:flex-nowrap justify-center space-x-4   flex flex-wrap  ${isAnimating ? 'animate-slideInBottom' : ''} `}>
                <div className=' min-[1025px]:text-[18px] min-[1081px]:w-[550px]  min-[1081px]:ml-[50px] pl-[18px] w-[350px]  mb-[60px] '>
                    <IoLocationOutline className='text-5xl cursor-pointer hover:text-red-600 ' onClick={() =>open('https://www.google.com/maps/place/Barakahu,+Islamabad,+Islamabad+Capital+Territory,+Pakistan/@33.7425048,73.1799007,16.58z/data=!4m15!1m8!3m7!1s0x38dfc180bf8c3149:0xfce8071e61b2e505!2sBarakahu,+Islamabad,+Islamabad+Capital+Territory,+Pakistan!3b1!8m2!3d33.7380556!4d73.1851121!16s%2Fg%2F1tdp7_lc!3m5!1s0x38dfc180bf8c3149:0xfce8071e61b2e505!8m2!3d33.7380556!4d73.1851121!16s%2Fg%2F1tdp7_lc?entry=ttu&g_ep=EgoyMDI0MDkxNi4wIKXMDSoASAFQAw%3D%3D')}/>
                    <a className='text-black font-bold w-full  my-[20px] text-[24px] hover:text-red-600 hover:cursor-pointer' onClick={() =>open('https://www.google.com/maps/place/Barakahu,+Islamabad,+Islamabad+Capital+Territory,+Pakistan/@33.7425048,73.1799007,16.58z/data=!4m15!1m8!3m7!1s0x38dfc180bf8c3149:0xfce8071e61b2e505!2sBarakahu,+Islamabad,+Islamabad+Capital+Territory,+Pakistan!3b1!8m2!3d33.7380556!4d73.1851121!16s%2Fg%2F1tdp7_lc!3m5!1s0x38dfc180bf8c3149:0xfce8071e61b2e505!8m2!3d33.7380556!4d73.1851121!16s%2Fg%2F1tdp7_lc?entry=ttu&g_ep=EgoyMDI0MDkxNi4wIKXMDSoASAFQAw%3D%3D')}>Physical</a>
                    <h2 className='text-blue-700 font-bold  w-full  my-[20px] opacity-90' >Nai Abadi ,Bharakahu, Islamabad, Pkaistan</h2>
                    
                </div>
                <div className=' min-[1025px]:text-[18px] min-[1081px]:w-[550px]  min-[1081px]:pl-[50px] w-[350px]  mb-[60px] '>
                    <MdOutlineMail className='text-5xl cursor-pointer hover:text-red-600' onClick={() =>open('mailto:minitronixs@gmail.com')}/>
                    <a onClick={() =>open('mailto:minitronixs@gmail.com')} className='text-black font-bold w-full text-[24px]  my-[20px] hover:text-red-600 hover:cursor-pointer'>Email</a>
                    <h2 className='text-blue-700 font-bold  w-full  my-[20px] opacity-90'>minitronixs@gmail.com</h2>
                    
                </div>
                <div className=' min-[1025px]:text-[18px] min-[1081px]:w-[550px] min-[1081px]:pl-[50px] w-[350px]  mb-[60px] '>
                    <FaWhatsapp className='text-5xl cursor-pointer hover:text-red-600' onClick={() =>open('https://wa.me/923455174586')}/>
                    <a onClick={() =>open('https://wa.me/923455174586')} className='text-black font-bold w-full text-[24px]  my-[20px] hover:text-red-600 hover:cursor-pointer'>Wathsapp</a>
                    <h2 className='text-blue-700 font-bold   w-full  my-[20px] opacity-90'>+92-345-5174586</h2>
                    
                </div>
                
                
                
            </section>
        </div>

    )
}

export default Contact