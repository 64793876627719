import React, { useState, useEffect } from 'react';
import { IoPersonCircleOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import bcrypt from 'bcryptjs';
import { useUser } from './UserContext';
import Loader from '../../Images/miniLoader.gif'
import BaseUrl from '../../BaseUrl';

const Login = () => {
  const [isAnimating, setIsAnimating] = useState(false);
  const { setrole } = useUser();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.get(`${BaseUrl}/user`);
      response?setIsLoading(false):setIsLoading(true);
      const users = response.data.message[0];
      const checkPassword = await bcrypt.compare(password, users.password);
      console.log(email, users.email)
      if (checkPassword && users.email === email) {
        sessionStorage.setItem('role', users.role);
        setrole(users.role);
        console.log(users.role);
        alert("Admin Login Successfully");
        setErrorMessage('Welcome Back');
        navigate("/admin"); 
      }
      else {
        setErrorMessage("Invalid email or password");
      }
    } catch (error) {
      console.error('Error during login:', error);
      setErrorMessage("An error occurred during login. Please try again.");
    }
  };
  useEffect(() => {
    setIsAnimating(true);
  }, []);


  return (
    <div className={`min-[451px]:min-h-[660px] min-[600px]:min-h-[670px]  bg-cover bg-center min-h-[650px]  flex items-center justify-center  fixed top-10 left-0 w-[100%] z-20 bg-[white] `}>
      <form onSubmit={handleLogin} className={`"backdrop-blur-lg p-8 bg-[#e9e2e2] w-[400px] h-[460px] rounded-lg shadow-lg" ${isAnimating ? 'animate-slideInTop' : ''}`}>

        <IoPersonCircleOutline className="text-7xl font-bold text-center ml-[40%] " />

        <div className="text-center mt-4"><span className="text-xl text-red-500">Admin Use Only</span></div>

        <div className="mb-8 mt-8">
          <label htmlFor="email" className="sr-only">Email</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white/30"
            placeholder="Email"
          />
        </div>

        <div className="mb-8">
          <label htmlFor="password" className="sr-only">Password</label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white/30"
            placeholder="Password"
          />
        </div>

        <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold mt-2 py-2 px-4 rounded-lg transition-colors" onClick={()=>{setIsLoading(true)}}>
          Sign in
        </button>



        <div className="text-center mt-4">
          {
            errorMessage !== '' || errorMessage !== null ? <span className="text-sm text-red-500">{errorMessage}</span> : <span className="text-sm text-red-500">Login here ! </span>
          }
          {
            isLoading? <span className="text-sm text-red-500 max-w-[100%] max-h-[10px] "><img className=' max-w-[170px] max-h-[150px] ml-[85px] mt-[0px]' src={Loader} alt='loading'/></span> : <span className="text-sm text-red-500">Login here ! </span>
          }

        </div>
      </form>
    </div>
  );
};

export default Login;
