const Countries = [
    { value: '+92', label: <><span className="fi fi-pk"></span> Pakistan (+92)</>, name: 'Pakistan' },
    { value: '+93', label: <><span className="fi fi-af"></span> Afghanistan (+93)</>, name: 'Afghanistan' },
    { value: '+355', label: <><span className="fi fi-al"></span> Albania (+355)</>, name: 'Albania' },
    { value: '+213', label: <><span className="fi fi-dz"></span> Algeria (+213)</>, name: 'Algeria' },
    { value: '+1', label: <><span className="fi fi-us"></span> United States (+1)</>, name: 'United States' },
    { value: '+376', label: <><span className="fi fi-ad"></span> Andorra (+376)</>, name: 'Andorra' },
    { value: '+244', label: <><span className="fi fi-ao"></span> Angola (+244)</>, name: 'Angola' },
    { value: '+1', label: <><span className="fi fi-ag"></span> Antigua and Barbuda (+1)</>, name: 'Antigua and Barbuda' },
    { value: '+54', label: <><span className="fi fi-ar"></span> Argentina (+54)</>, name: 'Argentina' },
    { value: '+374', label: <><span className="fi fi-am"></span> Armenia (+374)</>, name: 'Armenia' },
    { value: '+61', label: <><span className="fi fi-au"></span> Australia (+61)</>, name: 'Australia' },
    { value: '+43', label: <><span className="fi fi-at"></span> Austria (+43)</>, name: 'Austria' },
    { value: '+994', label: <><span className="fi fi-az"></span> Azerbaijan (+994)</>, name: 'Azerbaijan' },
    { value: '+1', label: <><span className="fi fi-bs"></span> Bahamas (+1)</>, name: 'Bahamas' },
    { value: '+973', label: <><span className="fi fi-bh"></span> Bahrain (+973)</>, name: 'Bahrain' },
    { value: '+880', label: <><span className="fi fi-bd"></span> Bangladesh (+880)</>, name: 'Bangladesh' },
    { value: '+1', label: <><span className="fi fi-bb"></span> Barbados (+1)</>, name: 'Barbados' },
    { value: '+375', label: <><span className="fi fi-by"></span> Belarus (+375)</>, name: 'Belarus' },
    { value: '+32', label: <><span className="fi fi-be"></span> Belgium (+32)</>, name: 'Belgium' },
    { value: '+501', label: <><span className="fi fi-bz"></span> Belize (+501)</>, name: 'Belize' },
    { value: '+229', label: <><span className="fi fi-bj"></span> Benin (+229)</>, name: 'Benin' },
    { value: '+975', label: <><span className="fi fi-bt"></span> Bhutan (+975)</>, name: 'Bhutan' },
    { value: '+591', label: <><span className="fi fi-bo"></span> Bolivia (+591)</>, name: 'Bolivia' },
    { value: '+387', label: <><span className="fi fi-ba"></span> Bosnia and Herzegovina (+387)</>, name: 'Bosnia and Herzegovina' },
    { value: '+267', label: <><span className="fi fi-bw"></span> Botswana (+267)</>, name: 'Botswana' },
    { value: '+55', label: <><span className="fi fi-br"></span> Brazil (+55)</>, name: 'Brazil' },
    { value: '+1284', label: <><span className="fi fi-vg"></span> British Virgin Islands (+1284)</>, name: 'British Virgin Islands' },
    { value: '+673', label: <><span className="fi fi-bn"></span> Brunei (+673)</>, name: 'Brunei' },
    { value: '+359', label: <><span className="fi fi-bg"></span> Bulgaria (+359)</>, name: 'Bulgaria' },
    { value: '+226', label: <><span className="fi fi-bf"></span> Burkina Faso (+226)</>, name: 'Burkina Faso' },
    { value: '+257', label: <><span className="fi fi-bi"></span> Burundi (+257)</>, name: 'Burundi' },
    { value: '+855', label: <><span className="fi fi-kh"></span> Cambodia (+855)</>, name: 'Cambodia' },
    { value: '+237', label: <><span className="fi fi-cm"></span> Cameroon (+237)</>, name: 'Cameroon' },
    { value: '+1', label: <><span className="fi fi-ca"></span> Canada (+1)</>, name: 'Canada' },
    { value: '+238', label: <><span className="fi fi-cv"></span> Cape Verde (+238)</>, name: 'Cape Verde' },
    { value: '+345', label: <><span className="fi fi-ky"></span> Cayman Islands (+345)</>, name: 'Cayman Islands' },
    { value: '+236', label: <><span className="fi fi-cf"></span> Central African Republic (+236)</>, name: 'Central African Republic' },
    { value: '+235', label: <><span className="fi fi-cg"></span> Chad (+235)</>, name: 'Chad' },
    { value: '+56', label: <><span className="fi fi-cl"></span> Chile (+56)</>, name: 'Chile' },
    { value: '+86', label: <><span className="fi fi-cn"></span> China (+86)</>, name: 'China' },
    { value: '+57', label: <><span className="fi fi-co"></span> Colombia (+57)</>, name: 'Colombia' },
    { value: '+269', label: <><span className="fi fi-km"></span> Comoros (+269)</>, name: 'Comoros' },
    { value: '+242', label: <><span className="fi fi-cd"></span> Democratic Republic of the Congo (+242)</>, name: 'Democratic Republic of the Congo' },
    { value: '+243', label: <><span className="fi fi-cg"></span> Republic of the Congo (+243)</>, name: 'Republic of the Congo' },
    { value: '+682', label: <><span className="fi fi-ck"></span> Cook Islands (+682)</>, name: 'Cook Islands' },
    { value: '+506', label: <><span className="fi fi-cr"></span> Costa Rica (+506)</>, name: 'Costa Rica' },
    { value: '+385', label: <><span className="fi fi-hr"></span> Croatia (+385)</>, name: 'Croatia' },
    { value: '+53', label: <><span className="fi fi-cu"></span> Cuba (+53)</>, name: 'Cuba' },
    { value: '+599', label: <><span className="fi fi-bq"></span> Curaçao (+599)</>, name: 'Curaçao' },
    { value: '+357', label: <><span className="fi fi-cy"></span> Cyprus (+357)</>, name: 'Cyprus' },
    { value: '+420', label: <><span className="fi fi-cz"></span> Czech Republic (+420)</>, name: 'Czech Republic' },
    { value: '+45', label: <><span className="fi fi-dk"></span> Denmark (+45)</>, name: 'Denmark' },
    { value: '+253', label: <><span className="fi fi-dj"></span> Djibouti (+253)</>, name: 'Djibouti' },
    { value: '+1', label: <><span className="fi fi-dm"></span> Dominica (+1)</>, name: 'Dominica' },
    { value: '+809', label: <><span className="fi fi-do"></span> Dominican Republic (+809)</>, name: 'Dominican Republic' },
    { value: '+593', label: <><span className="fi fi-ec"></span> Ecuador (+593)</>, name: 'Ecuador' },
    { value: '+20', label: <><span className="fi fi-eg"></span> Egypt (+20)</>, name: 'Egypt' },
    { value: '+503', label: <><span className="fi fi-sv"></span> El Salvador (+503)</>, name: 'El Salvador' },
    { value: '+240', label: <><span className="fi fi-gq"></span> Equatorial Guinea (+240)</>, name: 'Equatorial Guinea' },
    { value: '+291', label: <><span className="fi fi-er"></span> Eritrea (+291)</>, name: 'Eritrea' },
    { value: '+372', label: <><span className="fi fi-ee"></span> Estonia (+372)</>, name: 'Estonia' },
    { value: '+251', label: <><span className="fi fi-et"></span> Ethiopia (+251)</>, name: 'Ethiopia' },
    { value: '+500', label: <><span className="fi fi-fk"></span> Falkland Islands (+500)</>, name: 'Falkland Islands' },
    { value: '+298', label: <><span className="fi fi-fo"></span> Faroe Islands (+298)</>, name: 'Faroe Islands' },
    { value: '+679', label: <><span className="fi fi-fj"></span> Fiji (+679)</>, name: 'Fiji' },
    { value: '+358', label: <><span className="fi fi-fi"></span> Finland (+358)</>, name: 'Finland' },
    { value: '+33', label: <><span className="fi fi-fr"></span> France (+33)</>, name: 'France' },
    { value: '+241', label: <><span className="fi fi-ga"></span> Gabon (+241)</>, name: 'Gabon' },
    { value: '+220', label: <><span className="fi fi-gm"></span> Gambia (+220)</>, name: 'Gambia' },
    { value: '+995', label: <><span className="fi fi-ge"></span> Georgia (+995)</>, name: 'Georgia' },
    { value: '+49', label: <><span className="fi fi-de"></span> Germany (+49)</>, name: 'Germany' },
    { value: '+233', label: <><span className="fi fi-gh"></span> Ghana (+233)</>, name: 'Ghana' },
    { value: '+350', label: <><span className="fi fi-gi"></span> Gibraltar (+350)</>, name: 'Gibraltar' },
    { value: '+30', label: <><span className="fi fi-gr"></span> Greece (+30)</>, name: 'Greece' },
    { value: '+299', label: <><span className="fi fi-gl"></span> Greenland (+299)</>, name: 'Greenland' },
    { value: '+1473', label: <><span className="fi fi-gd"></span> Grenada (+1473)</>, name: 'Grenada' },
    { value: '+590', label: <><span className="fi fi-gp"></span> Guadeloupe (+590)</>, name: 'Guadeloupe' },
    { value: '+240', label: <><span className="fi fi-gq"></span> Guinea (+240)</>, name: 'Guinea' },
    { value: '+224', label: <><span className="fi fi-gw"></span> Guinea-Bissau (+224)</>, name: 'Guinea-Bissau' },
    { value: '+595', label: <><span className="fi fi-py"></span> Paraguay (+595)</>, name: 'Paraguay' },
    { value: '+509', label: <><span className="fi fi-ht"></span> Haiti (+509)</>, name: 'Haiti' },
    { value: '+504', label: <><span className="fi fi-hn"></span> Honduras (+504)</>, name: 'Honduras' },
    { value: '+36', label: <><span className="fi fi-hu"></span> Hungary (+36)</>, name: 'Hungary' },
    { value: '+354', label: <><span className="fi fi-is"></span> Iceland (+354)</>, name: 'Iceland' },
    { value: '+91', label: <><span className="fi fi-in"></span> India (+91)</>, name: 'India' },
    { value: '+62', label: <><span className="fi fi-id"></span> Indonesia (+62)</>, name: 'Indonesia' },
    { value: '+98', label: <><span className="fi fi-ir"></span> Iran (+98)</>, name: 'Iran' },
    { value: '+964', label: <><span className="fi fi-iq"></span> Iraq (+964)</>, name: 'Iraq' },
    { value: '+353', label: <><span className="fi fi-ie"></span> Ireland (+353)</>, name: 'Ireland' },
    { value: '+972', label: <><span className="fi fi-il"></span> Israel (+972)</>, name: 'Israel' },
    { value: '+39', label: <><span className="fi fi-it"></span> Italy (+39)</>, name: 'Italy' },
    { value: '+225', label: <><span className="fi fi-ci"></span> Ivory Coast (+225)</>, name: 'Ivory Coast' },
    { value: '+81', label: <><span className="fi fi-jp"></span> Japan (+81)</>, name: 'Japan' },
    { value: '+962', label: <><span className="fi fi-jo"></span> Jordan (+962)</>, name: 'Jordan' },
    { value: '+7', label: <><span className="fi fi-kz"></span> Kazakhstan (+7)</>, name: 'Kazakhstan' },
    { value: '+254', label: <><span className="fi fi-ke"></span> Kenya (+254)</>, name: 'Kenya' },
    { value: '+686', label: <><span className="fi fi-ki"></span> Kiribati (+686)</>, name: 'Kiribati' },
    { value: '+965', label: <><span className="fi fi-kw"></span> Kuwait (+965)</>, name: 'Kuwait' },
    { value: '+996', label: <><span className="fi fi-kg"></span> Kyrgyzstan (+996)</>, name: 'Kyrgyzstan' },
    { value: '+855', label: <><span className="fi fi-la"></span> Laos (+855)</>, name: 'Laos' },
    { value: '+371', label: <><span className="fi fi-lv"></span> Latvia (+371)</>, name: 'Latvia' },
    { value: '+961', label: <><span className="fi fi-lb"></span> Lebanon (+961)</>, name: 'Lebanon' },
    { value: '+266', label: <><span className="fi fi-ls"></span> Lesotho (+266)</>, name: 'Lesotho' },
    { value: '+231', label: <><span className="fi fi-lr"></span> Liberia (+231)</>, name: 'Liberia' },
    { value: '+218', label: <><span className="fi fi-ly"></span> Libya (+218)</>, name: 'Libya' },
    { value: '+423', label: <><span className="fi fi-li"></span> Liechtenstein (+423)</>, name: 'Liechtenstein' },
    { value: '+370', label: <><span className="fi fi-lt"></span> Lithuania (+370)</>, name: 'Lithuania' },
    { value: '+352', label: <><span className="fi fi-lu"></span> Luxembourg (+352)</>, name: 'Luxembourg' },
    { value: '+261', label: <><span className="fi fi-mg"></span> Madagascar (+261)</>, name: 'Madagascar' },
    { value: '+265', label: <><span className="fi fi-mw"></span> Malawi (+265)</>, name: 'Malawi' },
    { value: '+60', label: <><span className="fi fi-my"></span> Malaysia (+60)</>, name: 'Malaysia' },
    { value: '+960', label: <><span className="fi fi-mv"></span> Maldives (+960)</>, name: 'Maldives' },
    { value: '+223', label: <><span className="fi fi-ml"></span> Mali (+223)</>, name: 'Mali' },
    { value: '+356', label: <><span className="fi fi-mt"></span> Malta (+356)</>, name: 'Malta' },
    { value: '+692', label: <><span className="fi fi-mh"></span> Marshall Islands (+692)</>, name: 'Marshall Islands' },
    { value: '+596', label: <><span className="fi fi-mq"></span> Martinique (+596)</>, name: 'Martinique' },
    { value: '+222', label: <><span className="fi fi-mr"></span> Mauritania (+222)</>, name: 'Mauritania' },
    { value: '+230', label: <><span className="fi fi-mu"></span> Mauritius (+230)</>, name: 'Mauritius' },
    { value: '+262', label: <><span className="fi fi-re"></span> Mayotte (+262)</>, name: 'Mayotte' },
    { value: '+52', label: <><span className="fi fi-mx"></span> Mexico (+52)</>, name: 'Mexico' },
    { value: '+691', label: <><span className="fi fi-fm"></span> Micronesia (+691)</>, name: 'Micronesia' },
    { value: '+373', label: <><span className="fi fi-md"></span> Moldova (+373)</>, name: 'Moldova' },
    { value: '+377', label: <><span className="fi fi-mc"></span> Monaco (+377)</>, name: 'Monaco' },
    { value: '+976', label: <><span className="fi fi-mn"></span> Mongolia (+976)</>, name: 'Mongolia' },
    { value: '+382', label: <><span className="fi fi-me"></span> Montenegro (+382)</>, name: 'Montenegro' },
    { value: '+1664', label: <><span className="fi fi-ms"></span> Montserrat (+1664)</>, name: 'Montserrat' },
    { value: '+212', label: <><span className="fi fi-ma"></span> Morocco (+212)</>, name: 'Morocco' },
    { value: '+258', label: <><span className="fi fi-mz"></span> Mozambique (+258)</>, name: 'Mozambique' },
    { value: '+95', label: <><span className="fi fi-mm"></span> Myanmar (+95)</>, name: 'Myanmar' },
    { value: '+264', label: <><span className="fi fi-na"></span> Namibia (+264)</>, name: 'Namibia' },
    { value: '+674', label: <><span className="fi fi-nr"></span> Nauru (+674)</>, name: 'Nauru' },
    { value: '+977', label: <><span className="fi fi-np"></span> Nepal (+977)</>, name: 'Nepal' },
    { value: '+31', label: <><span className="fi fi-nl"></span> Netherlands (+31)</>, name: 'Netherlands' },
    { value: '+64', label: <><span className="fi fi-nz"></span> New Zealand (+64)</>, name: 'New Zealand' },
    { value: '+505', label: <><span className="fi fi-ni"></span> Nicaragua (+505)</>, name: 'Nicaragua' },
    { value: '+227', label: <><span className="fi fi-ne"></span> Niger (+227)</>, name: 'Niger' },
    { value: '+234', label: <><span className="fi fi-ng"></span> Nigeria (+234)</>, name: 'Nigeria' },
    { value: '+683', label: <><span className="fi fi-nu"></span> Niue (+683)</>, name: 'Niue' },
    { value: '+872', label: <><span className="fi fi-nf"></span> Norfolk Island (+872)</>, name: 'Norfolk Island' },
    { value: '+672', label: <><span className="fi fi-nf"></span> Northern Mariana Islands (+672)</>, name: 'Northern Mariana Islands' },
    { value: '+47', label: <><span className="fi fi-no"></span> Norway (+47)</>, name: 'Norway' },
    { value: '+968', label: <><span className="fi fi-om"></span> Oman (+968)</>, name: 'Oman' },
    { value: '+680', label: <><span className="fi fi-pw"></span> Palau (+680)</>, name: 'Palau' },
    { value: '+970', label: <><span className="fi fi-ps"></span> Palestine (+970)</>, name: 'Palestine' },
    { value: '+507', label: <><span className="fi fi-pa"></span> Panama (+507)</>, name: 'Panama' },
    { value: '+675', label: <><span className="fi fi-pg"></span> Papua New Guinea (+675)</>, name: 'Papua New Guinea' },
    { value: '+595', label: <><span className="fi fi-py"></span> Paraguay (+595)</>, name: 'Paraguay' },
    { value: '+51', label: <><span className="fi fi-pe"></span> Peru (+51)</>, name: 'Peru' },
    { value: '+63', label: <><span className="fi fi-ph"></span> Philippines (+63)</>, name: 'Philippines' },
    { value: '+48', label: <><span className="fi fi-pl"></span> Poland (+48)</>, name: 'Poland' },
    { value: '+351', label: <><span className="fi fi-pt"></span> Portugal (+351)</>, name: 'Portugal' },
    { value: '+1', label: <><span className="fi fi-pr"></span> Puerto Rico (+1)</>, name: 'Puerto Rico' },
    { value: '+974', label: <><span className="fi fi-qa"></span> Qatar (+974)</>, name: 'Qatar' },
    { value: '+40', label: <><span className="fi fi-ro"></span> Romania (+40)</>, name: 'Romania' },
    { value: '+7', label: <><span className="fi fi-ru"></span> Russia (+7)</>, name: 'Russia' },
    { value: '+250', label: <><span className="fi fi-rw"></span> Rwanda (+250)</>, name: 'Rwanda' },
    { value: '+590', label: <><span className="fi fi-bl"></span> Saint Barthélemy (+590)</>, name: 'Saint Barthélemy' },
    { value: '+1', label: <><span className="fi fi-kn"></span> Saint Kitts and Nevis (+1)</>, name: 'Saint Kitts and Nevis' },
    { value: '+590', label: <><span className="fi fi-mf"></span> Saint Martin (+590)</>, name: 'Saint Martin' },
    { value: '+1', label: <><span className="fi fi-lc"></span> Saint Lucia (+1)</>, name: 'Saint Lucia' },
    { value: '+1', label: <><span className="fi fi-sx"></span> Sint Maarten (+1)</>, name: 'Sint Maarten' },
    { value: '+378', label: <><span className="fi fi-sm"></span> San Marino (+378)</>, name: 'San Marino' },
    { value: '+239', label: <><span className="fi fi-st"></span> São Tomé and Príncipe (+239)</>, name: 'São Tomé and Príncipe' },
    { value: '+966', label: <><span className="fi fi-sa"></span> Saudi Arabia (+966)</>, name: 'Saudi Arabia' },
    { value: '+221', label: <><span className="fi fi-sn"></span> Senegal (+221)</>, name: 'Senegal' },
    { value: '+381', label: <><span className="fi fi-rs"></span> Serbia (+381)</>, name: 'Serbia' },
    { value: '+248', label: <><span className="fi fi-sc"></span> Seychelles (+248)</>, name: 'Seychelles' },
    { value: '+232', label: <><span className="fi fi-sl"></span> Sierra Leone (+232)</>, name: 'Sierra Leone' },
    { value: '+65', label: <><span className="fi fi-sg"></span> Singapore (+65)</>, name: 'Singapore' },
    { value: '+421', label: <><span className="fi fi-sk"></span> Slovakia (+421)</>, name: 'Slovakia' },
    { value: '+386', label: <><span className="fi fi-si"></span> Slovenia (+386)</>, name: 'Slovenia' },
    { value: '+677', label: <><span className="fi fi-sb"></span> Solomon Islands (+677)</>, name: 'Solomon Islands' },
    { value: '+252', label: <><span className="fi fi-so"></span> Somalia (+252)</>, name: 'Somalia' },
    { value: '+27', label: <><span className="fi fi-za"></span> South Africa (+27)</>, name: 'South Africa' },
    { value: '+82', label: <><span className="fi fi-kr"></span> South Korea (+82)</>, name: 'South Korea' },
    { value: '+34', label: <><span className="fi fi-es"></span> Spain (+34)</>, name: 'Spain' },
    { value: '+94', label: <><span className="fi fi-lk"></span> Sri Lanka (+94)</>, name: 'Sri Lanka' },
    { value: '+211', label: <><span className="fi fi-sd"></span> South Sudan (+211)</>, name: 'South Sudan' },
    { value: '+249', label: <><span className="fi fi-sd"></span> Sudan (+249)</>, name: 'Sudan' },
    { value: '+597', label: <><span className="fi fi-sr"></span> Suriname (+597)</>, name: 'Suriname' },
    { value: '+268', label: <><span className="fi fi-sz"></span> Eswatini (+268)</>, name: 'Eswatini' },
    { value: '+46', label: <><span className="fi fi-se"></span> Sweden (+46)</>, name: 'Sweden' },
    { value: '+41', label: <><span className="fi fi-ch"></span> Switzerland (+41)</>, name: 'Switzerland' },
    { value: '+963', label: <><span className="fi fi-sy"></span> Syria (+963)</>, name: 'Syria' },
    { value: '+886', label: <><span className="fi fi-tw"></span> Taiwan (+886)</>, name: 'Taiwan' },
    { value: '+992', label: <><span className="fi fi-tj"></span> Tajikistan (+992)</>, name: 'Tajikistan' },
    { value: '+255', label: <><span className="fi fi-tz"></span> Tanzania (+255)</>, name: 'Tanzania' },
    { value: '+66', label: <><span className="fi fi-th"></span> Thailand (+66)</>, name: 'Thailand' },
    { value: '+670', label: <><span className="fi fi-tl"></span> Timor-Leste (+670)</>, name: 'Timor-Leste' },
    { value: '+228', label: <><span className="fi fi-tg"></span> Togo (+228)</>, name: 'Togo' },
    { value: '+690', label: <><span className="fi fi-tk"></span> Tokelau (+690)</>, name: 'Tokelau' },
    { value: '+676', label: <><span className="fi fi-to"></span> Tonga (+676)</>, name: 'Tonga' },
    { value: '+1868', label: <><span className="fi fi-tt"></span> Trinidad and Tobago (+1868)</>, name: 'Trinidad and Tobago' },
    { value: '+216', label: <><span className="fi fi-tn"></span> Tunisia (+216)</>, name: 'Tunisia' },
    { value: '+90', label: <><span className="fi fi-tr"></span> Turkey (+90)</>, name: 'Turkey' },
    { value: '+993', label: <><span className="fi fi-tm"></span> Turkmenistan (+993)</>, name: 'Turkmenistan' },
    { value: '+1', label: <><span className="fi fi-tc"></span> Turks and Caicos Islands (+1)</>, name: 'Turks and Caicos Islands' },
    { value: '+688', label: <><span className="fi fi-tv"></span> Tuvalu (+688)</>, name: 'Tuvalu' },
    { value: '+256', label: <><span className="fi fi-ug"></span> Uganda (+256)</>, name: 'Uganda' },
    { value: '+380', label: <><span className="fi fi-ua"></span> Ukraine (+380)</>, name: 'Ukraine' },
    { value: '+971', label: <><span className="fi fi-ae"></span> United Arab Emirates (+971)</>, name: 'United Arab Emirates' },
    { value: '+44', label: <><span className="fi fi-gb"></span> United Kingdom (+44)</>, name: 'United Kingdom' },
    { value: '+1', label: <><span className="fi fi-us"></span> United States (+1)</>, name: 'United States' },
    { value: '+598', label: <><span className="fi fi-uy"></span> Uruguay (+598)</>, name: 'Uruguay' },
    { value: '+998', label: <><span className="fi fi-uz"></span> Uzbekistan (+998)</>, name: 'Uzbekistan' },
    { value: '+678', label: <><span className="fi fi-vu"></span> Vanuatu (+678)</>, name: 'Vanuatu' },
    { value: '+379', label: <><span className="fi fi-va"></span> Vatican City (+379)</>, name: 'Vatican City' },
    { value: '+58', label: <><span className="fi fi-ve"></span> Venezuela (+58)</>, name: 'Venezuela' },
    { value: '+84', label: <><span className="fi fi-vn"></span> Vietnam (+84)</>, name: 'Vietnam' },
    { value: '+681', label: <><span className="fi fi-wf"></span> Wallis and Futuna (+681)</>, name: 'Wallis and Futuna' },
    { value: '+967', label: <><span className="fi fi-ye"></span> Yemen (+967)</>, name: 'Yemen' },
    { value: '+260', label: <><span className="fi fi-zm"></span> Zambia (+260)</>, name: 'Zambia' },
    { value: '+255', label: <><span className="fi fi-zw"></span> Zimbabwe (+255)</>, name: 'Zimbabwe' }
];

export default Countries;
