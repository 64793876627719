import React ,{useEffect, useState} from 'react';
import { CiCircleRemove } from "react-icons/ci";
import { FiMinus, FiPlus } from "react-icons/fi";
import { LuShoppingCart } from "react-icons/lu";
import { useUser } from '../Authentication/UserContext';
import BaseUrl from '../../BaseUrl';
import { useNavigate } from 'react-router-dom';

const CartItems = ({ closeCart }) => {
    const storedCart = JSON.parse(localStorage.getItem('CartItems')) || [];

    const navigate=useNavigate();
    const { cart,setCart ,DAmount } = useUser();
    const [Total,setTotal]=useState(0);
    const [Length,setLength]=useState(0);
    console.log(cart);

    const HandleCheckOut=()=>{
        closeCart();
        navigate('/payment')
    }

    useEffect(() => {
        if (storedCart.length > 0) {
            setCart(storedCart);
        }
    }, []);

    useEffect(() => {
      if (cart.length > 0) {
        const newTotal = cart.reduce((acc, item) => acc + (item.price * item.quantity), 0);
        setTotal(newTotal);
    } else {
        setTotal(0); // Reset total if cart is empty
    }
    setLength(cart.length);
    localStorage.setItem('CartItems', JSON.stringify(cart));
    localStorage.setItem('DAmount', JSON.stringify(DAmount));
    }, [cart]);



    const RemoveCartItem = (item) => {
        setCart(cart.filter((item1) => item1._id !== item._id));
      };
      const HandleQuantityChange = (item, num) => {
        setCart(
          cart.map((cartItem) => {
            if (cartItem._id === item._id) {
              const newQuantity = cartItem.quantity + num;
      
              // Ensure newQuantity is between 1 and 10
              if (newQuantity < 1) {
                return { ...cartItem, quantity: 1 }; // Set to 1 if below 1
              } else if (newQuantity > parseInt(item.originalQuantity)) {
                return { ...cartItem, quantity: cartItem.quantity }; // Set to 10 if above 10
              }
      
              return { ...cartItem, quantity: newQuantity }; // Update with the new quantity
            }
            return cartItem;
          })
        );
      };
      
      
      
    
    return (
        <div
            className="min-w-[410px]  max-[425px]:min-w-[100%] min-h-[700px] bg-white fixed top-0 right-0 bottom-0 z-40 transition-transform transform translate-x-0 duration-300 animate-slideInRight" >
            <h1 className="text-[#212020] p-2 text-[20px]  fixed top-0 left-0 font-bold"> Your Cart</h1>
            <CiCircleRemove
                className="text-[#212020] p-2 text-[3.5rem] hover:cursor-pointer hover:text-red-500 fixed top-0 right-0 font-bold"
                onClick={closeCart}
            />
            <div className='min-w-[100%]   min-h-[420px] max-h-[420px]   fixed top-0 left-0  mt-[60px]  space-x-0  overflow-y-scroll custom-scrollbar'>
                {
                    cart.length > 0 ? cart.map((item, index) => (
                        <div key={index} className=' max-[425px]:justify-center max-[425px]:space-x-0 max-h-[60px] flex flex-row justify-around space-x-2 mb-[20px] mx-2'>
                            <div className=' max-h-[60px] max-w-[60px]'>
                                <img src={`${item.picture}`} alt='img' />
                            </div>
                            <div className=' max-h-[60px] max-[425px]:min-w-[100px] min-w-[190px]'>
                                <p className='font-bold text-[#24272D]  '>{item.name}</p>
                                <div className='flex flex-row justify-around'>
                                    <h1 className='flex flex-row w-[80px]  justify-around items-center text-[#24272D]'>
                                        <FiMinus className='hover:bg-red-500  transition duration-300 ease-in-out ' onClick={()=>{HandleQuantityChange(item,-1)}} />
                                        <h1 className=''>{item.quantity}</h1>
                                        <FiPlus className='hover:bg-red-500  transition duration-300 ease-in-out' onClick={()=>{HandleQuantityChange(item,1)}} />

                                    </h1>
                                    <CiCircleRemove className=' text-red-500 rounded-[2%]  text-2xl hover:font-extrabold hover:cursor-pointer transition duration-300 ease-in-out ' onClick={()=>RemoveCartItem(item)} />
                                </div>

                            </div>
                            <div className=' max-h-[60px] min-w-[100px] '>
                                <h1 className='text-[15px] text-right text-[#24272D]'>Rs{item.price *item.quantity}</h1>
                                <h1 className='text-[12px] opacity-80  line-through text-right '>Rs{(parseInt(item.price)*item.quantity+(parseInt(item.price)*0.2))}</h1>
                                <h1 className='text-[12px] text-right text-[#3dc93d]'>Save Rs {(parseInt(item.price)*0.2)*item.quantity}</h1>
                            </div>
                        </div>
                    )):<div className='text-black text-xl min-w-[100%] min-h-[450px] flex flex-col items-center justify-center space-y-2 mt-[60px]'>
                        <LuShoppingCart className='text-5xl' /> 
                        <h1 className='font-bold'>Your Cart Is Empty</h1>
                        <h1 className='text-[18px]'>Fill your cart with amazing items</h1>
                        <buton className='bg-blue-700  text-white min-w-[130px] min-h-[35px] flex justify-center items-center rounded-ss-lg cursor-pointer hover:bg-blue-500' onClick={closeCart}>Shop Now</buton>

                    </div>
                }


            </div>

            {
                cart.length>0?
                <div className='min-w-[100%] fixed bottom-3 left-0   min-h-[160px]  z-50 mt-[0px]'>
                <div className='flex flex-row   justify-between mb-[10px] min-h-[10px] px-2'>
                    <p className='font-bold   '>Total</p>
                    <p className='font-bold  '>Rs {Total}</p>
                </div>
                <div className='flex flex-row   justify-between mb-[10px] min-h-[10px] px-2'>
                    <p className='  '>Shipping Fee</p>
                    <p className=' '>Rs {DAmount}</p>
                </div>
                <div className='flex flex-row   justify-between mb-[10px] min-h-[10px] px-2'>
                    <p className='font-bold  '>SubTotal</p>
                    <p className='font-bold  '>Rs {Total+parseInt(DAmount)}</p>
                </div>
                <div className='flex  justify-center items-center mb-[10px] max-h-[50px] min-h-[50px] bg-blue-700 text-white text-[24px] hover:bg-blue-500 mx-2' onClick={HandleCheckOut}>
                    <LuShoppingCart onClick={HandleCheckOut}/> Checkout
                </div>
                <div className='flex  justify-center items-center mb-[10px] max-h-[30px] min-h-[30px] text-sm  text-[#262323] hover:text-red-500 hover:cursor-pointer mx-2' onClick={closeCart}>
                    Continue Shopping
                </div>



            </div>:''
            }
        </div>
    );
};

export default CartItems;