import React, { useState, useEffect } from 'react';
import aboutImg from '../../Images/AboutUsLogo.jpg'
import CEO from '../../Images/CEO.png'

const About = () => {
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        setIsAnimating(true);
    }, []);
    return (
        <div className={` ${isAnimating ? 'animate-fadeUp' : ''}`}>
            <section className={` min-[1025px]:min-h-[37rem] max-[426px]:mt-[80px]  min-[640px]:flex-row flex flex-col  flex-wrap px-[40px] min-h-[29rem] font-serif   ${isAnimating ? 'animate-fadeIn' : ''} `}>
                <div className={` w-[100%] min-[640px]:w-[50%] flex justify-center items-center ${isAnimating ? 'animate-slideInLeft' : ''}`}>
                    <img className={` min-[769px]:w-[400px] min-[769px]:h-[500px] w-[200px] h-[400px] `} src={aboutImg} alt='About Robo Image' />
                </div>
                <div className={` min-[640px]:w-[50%] min-[1024px]:pt-[120px] w-[100%] pt-[50px] ${isAnimating ? 'animate-slideInTop' : ''}`}>
                    <h1 className='min-[1025px]:text-5xl text-4xl '>Mini<span className='text-[#3141ef] '>tronix</span>?</h1>
                    <p className=' text-[#524c4c] leading-9 pt-[20px]'>Minitronix is an innovative educational enterprise focused on designing and delivering cutting-edge <mark>STEAM resources</mark> and training for <mark>school-age children</mark>.This an educational company that helps kids learn about science, technology, engineering, and math. We make fun and easy-to-use kits that let kids experiment and explore. It’s like having a <mark>science lab at home!</mark> With a commitment to enhancing learning through creative educational methodologies, we ensure that students not only learn effectively but are also inspired to explore. Our dedicated team of Engineers and Scientists is passionate about empowering the <mark>next generation</mark> with quality, <mark>innovative educational tools</mark> and experiences.</p>
                </div>
            </section>
            <section className={` bg-custom-Yellow-BG opacity min-[1025px]:min-h-[39rem] min-[640px]:flex-row flex flex-col  flex-wrap px-[40px] min-h-[29rem] font-serif   ${isAnimating ? 'animate-fadeIn' : ''} `}>
                <div className={` min-[640px]:w-[50%] min-[1024px]:pt-[170px] w-[100%] pt-[50px]  ${isAnimating ? 'animate-slideInTop' : ''}`}>
                    <h1 className='min-[1025px]:text-5xl text-4xl font-bold'>Message From <span className='text-[#3141ef] '>CEO</span></h1>
                    <p className=' text-[#100f0f] leading-9 pt-[30px]'>Hello, everyone! As the CEO of Minitronix, I want to share with you the true purpose behind our platform. At Minitronix, we're not just about selling products; we're committed to providing knowledge and inspiration to young learners. Our main goal is to spread this knowledge far and wide, enriching the lives of children with the joy of learning and the excitement of discovery. Join us as we embark on this journey to empower the next generation with the tools they need for a brighter future.</p>
                </div>
                <div className={` w-[100%] min-[640px]:w-[50%] flex justify-center items-center ${isAnimating ? 'animate-slideInRight' : ''}`}>
                    <img className={` min-[769px]:w-[400px] min-[769px]:h-[400px] w-[300px] h-[300px] rounded-[50%] `} src={CEO} alt='About Robo Image' />
                </div>

            </section>
        </div>

    )
}

export default About