  import React, { useState, useEffect } from 'react';
  import { useNavigate } from 'react-router-dom';
  import { useUser } from '../Authentication/UserContext';
  import axios from 'axios'
  import { IoIosAddCircleOutline } from "react-icons/io";
  import { CiDeliveryTruck } from "react-icons/ci";
  import { MdEdit } from "react-icons/md";
  import { AiOutlineDelete } from "react-icons/ai";
  import BaseUrl from '../../BaseUrl'
  import ComingSoon from '../../Images/ComingSoon.jpg'
  import Loader from '../../Images/loader.gif'
  import Loader1 from '../../Images/miniLoader.gif'

  import { confirmAlert } from 'react-confirm-alert';
  import 'react-confirm-alert/src/react-confirm-alert.css';


  import {
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Button, TextField
  } from '@mui/material';


  const Admin = ({ openCart }) => {
    const { setCart, cart } = useUser();
    const { role, setrole } = useUser(); // Assume useUser provides role and setRole
    const navigate = useNavigate();
    const [isAnimating, setIsAnimating] = useState(false);
    const [hoveredItem, setHovered] = useState(null);
    const [itemsData, setItemsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [Amount, setAmount] = useState('');
    const [openAmount, setOpenAmount] = useState(false);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [editId, setEditId] = useState(null);
    const [formData, setFormData] = useState({
      name: '',
      description: '',
      quantity: '',
      price: '',
      picture: null,
      picture2: null
    });

    const AddCartItems = (item) => {
      const itemExists = cart.some(cartItem => cartItem._id === item._id);

      if (!itemExists) {
        setCart([...cart, {
          _id: item._id,
          name: item.name,
          price: item.price,
          picture: item.picture,
          originalQuantity: item.quantity,
          quantity: 1,
        }]);
      }

      openCart();
    };

    const ItemDetPage = (obj) => {
      navigate("/itemdet", { state: { person: obj } });
    }

    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClickOpen1 = () => {
      setOpenAmount(true);
    };

    const handleClose = () => {
      setOpen(false);
      setOpenEdit(false);
      setOpenDialog(false);
      setFormData({
        name: '',
        description: '',
        quantity: '',
        price: '',
        picture: null,
        picture2: null
      });
      setEditId(null);
    };
    const handleClose1 = () => {
      setOpenAmount(false);
      setOpenDialog(false);
      setAmount('')
    };
    const handleAddItem = async () => {
      setIsLoading1(true);
      const data = new FormData();
      data.append('name', formData.name);
      data.append('description', formData.description);
      data.append('quantity', formData.quantity);
      data.append('price', formData.price);
      

      if (formData.picture) {
        data.append('picture', formData.picture);
        console.log(formData.picture);
      }
      if (formData.picture2) {
        data.append('picture2', formData.picture2);
        console.log(formData.picture2);
      }
      try {

        if (editId) {
          await axios.put(`${BaseUrl}/items/${editId}`, data).then((res) => {
            console.log(res.data);
            res?setIsLoading1(false):setIsLoading1(true);
          });

          alert('Item Updated Successfully');
        } else {
          const res=await axios.post(`${BaseUrl}/items`, data);
          res?setIsLoading1(false):setIsLoading1(true);
          alert('Item Added Successfully');
        }

        const itemsRes = await axios.get(`${BaseUrl}/items`);
        setItemsData(itemsRes.data.message);
        handleClose();
      } catch (err) {
        alert('Error in processing request');
        console.log(err);
      }
    };
    const handleAddItem1 = async () => {
      setIsLoading2(true);
      try {
          console.log(Amount);
          await axios.put(`${BaseUrl}/delivery`, { amount: Amount }).then((res) => {
            console.log(res.data);
            res?setIsLoading2(false):setIsLoading2(true);
          });

          alert('Amount Updated Successfully');
        handleClose1();
      } catch (err) {
        alert('Error in processing request');
        console.log(err);
      }
    };
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
    };
    const handleInputChange1 = (event) => {
      setAmount(event.target.value)
      console.log(Amount);
    };

    const handleFileChange = (event) => {
      const { name, files } = event.target;
      if (name === 'picture') {
        setFormData({ ...formData, picture: files[0] });
      } else if (name === 'picture2') {
        setFormData({ ...formData, picture2: files[0] });
      }
    };
    const handleEdit = (item) => {
      setFormData({
        name: item.name,
        description: item.description,
        quantity: item.quantity,
        price: item.price,
        picture: item.picture,
        picture2: item.picture2,
      });
      setEditId(item._id);
      setOpenEdit(true);
    };
    const handleDelete = async (id) => {
      confirmAlert({
        title: 'Confirm to delete',
        message: 'Are you sure you want to delete this item?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              try {
                await axios.delete(`${BaseUrl}/items/${id}`);
                alert('Item Deleted Successfully');
                const itemsRes = await axios.get(`${BaseUrl}/items`);
                setItemsData(itemsRes.data.message);
              } catch (err) {
                alert('Error in deleting item');
                console.log(err);
              }
            },
          },
          {
            label: 'No',
            onClick: () => {
              console.log('Cancelled');
            },
          },
        ],
      });
    };
    


    useEffect(() => {
      const fetchData = async () => {
        try {
          const itemsRes = await axios.get(`${BaseUrl}/items`);
          setItemsData(itemsRes.data.message);
          console.log(itemsRes);
          setIsLoading(false);
        } catch (err) {
          console.log(err);
          setIsLoading(false);
        }
      };

      setIsAnimating(true);
      fetchData();
    }, []);

    return (
      <div className={`${isAnimating ? 'animate-fadeUp' : ''} overflowFooter max-[426px]:mt-[60px]`}>
        <CiDeliveryTruck className='  rounded-[5%]  text-5xl font-black text-white bg-red-600 hover:bg-blue-500 hover:cursor-pointer hover: transition duration-500 ease-in-out float-right mt-[4rem] mb-[2rem] mr-[2rem] ' onClick={handleClickOpen1} />
        <IoIosAddCircleOutline className='  rounded-[5%]  text-5xl font-black text-white bg-red-600 hover:bg-blue-500 hover:cursor-pointer hover: transition duration-500 ease-in-out float-right mt-[4rem] mb-[2rem] mr-[2rem] ' onClick={handleClickOpen} />

        <section className={`min-[1080px]:mt-[100px]  w-[100%] min-[1025px]:min-h-[37rem]  min-[1025px]:grid min-[1025px]:grid-cols-3 min-[1025px]:object-cover min-[712px]:space-x-4 min-[1025px]:flex-nowrap justify-center space-x-0   flex flex-wrap `}>
          {
            isLoading ?
              <img className=' max-w-[10px] max-h-[10px] ' src={Loader} alt='loading' /> :
              itemsData.length > 0 ?
                itemsData.map((item, index) => (
                  <div key={index} className='min-[1025px]:text-[18px] min-[1081px]:w-[550px]  min-[1081px]:ml-[20px] pl-[18px] w-[350px]  mb-[60px] '>
                    {
                      item.picture ? <img className=' min-[1025px]:h-[320px] min-[1025px]:w-[450px] min-[1081px]:w-[320px] min-[1100px]:w-[400px] min-[1300px]:w-[450px]  rounded-[5%] hover:transform hover:-translate-y-3 transition-all duration-1000 ease-in-out hover:cursor-context-menu'
                        src={hoveredItem === index ? `${item.picture2}` : `${item.picture}`}
                        alt={item.name}
                        onMouseEnter={() => setHovered(index)}
                        onMouseLeave={() => setHovered(null)} /> : 'No Image'
                    }
                    <div className='flex flex-row   '>
                      <MdEdit className='  rounded-[25%]  text-[2.6rem]  text-white bg-red-600 hover:bg-blue-500 hover:cursor-pointer hover: transition duration-500 ease-in-out float-right mr-[4rem]   ' onClick={() => handleEdit(item)} />
                      <AiOutlineDelete className='  rounded-[25%]  text-[2.6rem]  text-white bg-red-600 hover:bg-blue-500 hover:cursor-pointer hover: transition duration-500 ease-in-out float-right mr-[4rem]' onClick={() => handleDelete(item._id)} />
                    </div>
                    <h1 className='text-black font-bold w-full  my-[20px] hover:text-red-600 hover:cursor-context-menu'>{item.name || 'none'} </h1>
                    <h2 className='text-blue-700 font-bold  w-full  my-[20px]'>₨ {item.price || 'none'}    <span className='min-[1025px]:text-[14px] text-[12px] opacity-80  font-medium line-through '>₨ {parseInt(item.price) + (parseInt(item.price) * 0.2) || 'none'}</span></h2>
                    {
                      parseInt(item.quantity) !== 0 ?
                        <button className=' bg-blue-700 rounded-[5%] w-[120px] h-[40px] text-white hover:bg-red-500 hover:cursor-none'  >In Stock</button> :
                        <button className=' bg-red-500 rounded-[5%] w-[120px] h-[40px] text-white hover:bg-blue-500 hover:cursor-none'  >Out Of Stock</button>
                    }
                  </div>
                )) :
                <h1 className='min-[426px]:text-xl min-[769px]:text-start min-[769px]:ml-[50px] min-[769px]:text-2xl min-[1025px]:ml-[20px] min-[1081px]:text-3xl min-[1081px]:pt-[50px] min-[1081px]:ml-[45px] text-[18px] text-[#192A3D]  h-[54px] text-center  font-serif'>No Item Found</h1>
          }
          {
            itemsData.length > 0 ?
              <>
                <div className='min-[1025px]:text-[18px] min-[1081px]:w-[550px] opacity-50 min-[1081px]:ml-[20px] pl-[18px] w-[350px]  mb-[60px] hover:cursor-not-allowed '>
                  <img className=' min-[1025px]:h-[320px] min-[1025px]:w-[450px] min-[1081px]:w-[320px] min-[1100px]:w-[400px] min-[1300px]:w-[450px]  rounded-[5%] hover:transform hover:-translate-y-3 transition-all duration-500' src={ComingSoon} alt='coming Soon' />
                  <h1 className='text-black font-bold w-full  my-[20px]  '>Mini-Techstarter</h1>
                  <h2 className='text-blue-700 font-bold  w-full  my-[20px]'>₨ 5500  <span className='min-[1025px]:text-[14px] text-[12px] opacity-80  font-medium line-through '>₨ 6600</span></h2>
                </div>
              </> : ''
          }
        </section>
        <Dialog open={open || openEdit} onClose={handleClose}>
          <DialogTitle>{editId ? 'Update Item' : 'Add Item'}</DialogTitle>
          <DialogContent>
            <DialogContentText>Please fill the Details below.</DialogContentText>
            <TextField
              margin="dense"
              name="name"
              label="Name"
              value={formData.name}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              margin="dense"
              name="description"
              label="Description"
              type='text'
              value={formData.description}
              onChange={handleInputChange}
              fullWidth
              multiline={true}
            />
            <TextField
              margin="dense"
              name="quantity"
              label="Quantity"
              type='text'
              value={formData.quantity}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              margin="dense"
              name="price"
              label="Price"
              type='text'
              value={formData.price}
              onChange={handleInputChange}
              fullWidth
            />



            
            <input 
              className='ml-[30px]'
              type="file"
              accept="image/*"
              name="picture"
              onChange={handleFileChange}
              style={{ marginTop: '10px' }}
            />
            <label className='min-h-[40px] font-medium'>---Display Pic</label> 
            <br/>
            
            <input 
              className='ml-[30px]'
              type="file"
              accept="image/*"
              name="picture2"
              onChange={handleFileChange}
              style={{ marginTop: '10px' }}
            />
            <label className='min-h-[40px] font-medium'>---Hover Pic</label> 
          </DialogContent>
          <DialogActions>
          {
              isLoading1? <span className="text-sm text-red-500  w-[200px] h-[60px]"><img className='w-[200px] h-[60px]' src={Loader1} alt='loading'/></span> :''
            }
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleAddItem}>{editId ? 'Update' : 'Add'}</Button>
          </DialogActions>
        </Dialog>


        <Dialog open={openAmount} onClose={handleClose1}>
          <DialogTitle>{'Add Delivery Amount'}</DialogTitle>
          <DialogContent>
            <DialogContentText>Please fill the Details below.</DialogContentText>
            <TextField
              margin="dense"
              name="amount"
              label="Amount"
              value={Amount}
              onChange={handleInputChange1}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
          {
              isLoading2? <span className="text-sm text-red-500  w-[200px] h-[60px]"><img className='w-[200px] h-[60px]' src={Loader1} alt='loading'/></span> :''
            }
            <Button onClick={handleClose1}>Cancel</Button>
            <Button onClick={handleAddItem1}>{'Add'}</Button>
          </DialogActions>
        </Dialog>

      </div>

    );
  };

  export default Admin