import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../Authentication/UserContext';
import BaseUrl from '../../BaseUrl'
import { FiMinus, FiPlus } from "react-icons/fi";
import SS1 from '../../Images/SS1.png'
import SS2 from '../../Images/SS2.png'
import SS3 from '../../Images/SS3.png'

const ItemDet = ({ openCart }) => {
  const { setCart, cart } = useUser();
  const [isAnimating, setIsAnimating] = useState(false);
  const [quantity, setquantity] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const { person } = location.state || {};
  console.log(person.description);

  const images = [
    `${person.picture}`,
    `${person.picture2}`
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Change the image every 1 second
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [images.length]);

  useEffect(() => {
    setIsAnimating(true);
    window.scrollTo(0, 0);

    const foundItem = cart.find(items => items._id === person._id);
    if (foundItem) {
      setquantity(foundItem.quantity);
    }
  }, [cart, person._id]); // Add dependencies if cart or person may change


  const AddCartItems = (item, type) => {
    const itemExists = cart.some(cartItem => cartItem._id === item._id);

    if (!itemExists) {
      setCart([...cart, {
        _id: item._id,
        name: item.name,
        price: item.price,
        picture: item.picture,
        originalQuantity: item.quantity,
        quantity: 1,
      }]);
    }
    type === 'ADD' ? openCart() : navigate('/payment');

  };
  return (
    <div className={` ${isAnimating ? 'animate-fadeUp' : ''}`}>
      <section className={` min-[1025px]:min-h-[39rem] min-[640px]:flex-row flex flex-col  flex-wrap px-[40px] min-h-[29rem] font-serif   ${isAnimating ? 'animate-fadeIn' : ''} `}>
        <div className={` w-[100%] min-[640px]:w-[50%] max-[640px]:mt-[-140px] flex mt-[0px] pt-[80px] justify-center ${isAnimating ? 'animate-slideInLeft' : ''}`}>
          <img className={` min-[769px]:w-[500px] min-[769px]:h-[500px] max-[640px]:mt-[90px] mr-[20px] w-[500px] h-[400px] animate-fadeIn `} src={`${images[currentImageIndex]}`} alt='About Robo Image' />
        </div>
        <div className={`  min-[640px]:w-[50%] min-[1024px]:pt-[60px] w-[100%] pt-[50px] ${isAnimating ? 'animate-slideInTop' : ''}`}>
          <h1 className=' min-[1025px]:text-4xl text-3xl font-bold mb-[10px] '>{person.name}</h1>
          <h2 className=' text-blue-700 font-bold  w-full  text-xl my-[20px]'>₨ {person.price}    <span className='min-[1025px]:text-[14px] text-[12px] opacity-80  font-medium line-through '>₨ {parseInt(person.price) + (parseInt(person.price) * 0.2) || 'none'}</span></h2>
          <div className='flex  mb-[10px] justify-around flex-row-reverse'>
            {
              parseInt(person.quantity) !== 0 ? <p className='pt-[20px]'> In Stock</p> : <p className='pt-[20px] text-red-500'> Out Of Stock</p>
            }
            <h1 className=' min-[1025px]:text-4xl text-3xl flex flex-row borderBlue min-w-[110px] min-h-[50px] justify-around items-center'>
              <h1 className='text-[30px]'>{quantity} /{person.quantity}</h1>

            </h1>
          </div>
          {
            parseInt(person.quantity) !== 0 ?
              <>
                <button className=' bg-blue-700 rounded-[2%] text-[18px] mb-[10px] mt-[10px] w-[100%] h-[40px] text-white hover:bg-red-500 hover:cursor-pointer transition duration-300 ease-in-out ' onClick={() => AddCartItems(person, 'ADD')}>Add to cart</button>
                <button className=' bg-black rounded-[2%] text-[18px]  mb-[10px] mt-[10px] w-[100%] h-[40px] text-white hover:bg-red-500 hover:cursor-pointer transition duration-300 ease-in-out' onClick={() => AddCartItems(person, 'BUY')}>Buy Now</button>
              </> :
              <button className=' bg-red-500 rounded-[2%] text-[18px] mb-[10px] mt-[10px] w-[100%] h-[40px] text-white hover:bg-blue-500 hover:cursor-not-allowed transition duration-300 ease-in-out '>Out Of Stock</button>
          }
          <p
            className='text-[#524c4c] leading-9 pt-[20px] mb-[10px]'
            dangerouslySetInnerHTML={{ __html: person.description.replace(/\n/g, '<br />') }}
          ></p>
          <div className='flex flex-row justify-around my-[10px]'>
            <img src={SS3} alt='SS1' className=' min-[1025px]:min-w-[120px] min-[1025px]:min-h-[110px] w-[100px] h-[110px]' />
            <img src={SS2} alt='SS2' className=' min-[1025px]:min-w-[120px] min-[1025px]:min-h-[110px] w-[100px] h-[110px]' />
            <img src={SS1} alt='SS3' className=' min-[1025px]:min-w-[120px] min-[1025px]:min-h-[110px] w-[100px] h-[110px]' />
          </div>
        </div>
      </section>

    </div>

  )
}

export default ItemDet