import React ,{useState , useEffect} from "react";

const ReturnPolicy = () => {
  const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        setIsAnimating(true);
    }, []);
    
  return (
    <div className={`max-[600px]:mx-0 max-[426px]:px-10 max-[426px]:mt-[100px]  container mx-8  p-6 px-28 my-8 font-serif ${isAnimating? 'animate-slideInRight':''}`}>
      <p className="text-[#524c4c] leading-9">Thank you for shopping at Minitronix <mark>www.minitronix.org</mark>. We are committed to ensuring the satisfaction of our customers. Please carefully read our return policy below.</p>

      <h2 className="max-[600px]:text-3xl text-4xl font-semibold my-5 text-[#2e2b2b]">Return Period</h2>
      <p className="text-[#524c4c] leading-9">You may return your purchase within 7 days of receipt.</p>

      <h2 className="max-[600px]:text-3xl text-4xl font-semibold my-5 text-[#2e2b2b]">Eligibility</h2>
      <p className="text-[#524c4c] leading-9">To be eligible for a return, your item must be unused, in the same condition in which you received it, and in the original packaging.</p>

      <h2 className="max-[600px]:text-3xl text-4xl font-semibold my-5 text-[#2e2b2b]">Return Shipping</h2>
      <p className="text-[#524c4c] leading-9">The customer is responsible for paying for their own shipping costs for returning their item.</p>

      <h2 className="max-[600px]:text-3xl text-4xl font-semibold my-5 text-[#2e2b2b]">Refunds</h2>
      <p className="text-[#524c4c] leading-9">Once we have received and inspected your return, we will notify you via email of the approval or rejection of your refund...</p>
    </div>
  );
};

export default ReturnPolicy;
