import React ,{useState , useEffect} from "react";

const PrivacyPolicy = () => {
  const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        setIsAnimating(true);
    }, []);

  return (
    <div className={`max-[600px]:mx-0 max-[426px]:px-10 max-[426px]:mt-[100px]  container mx-8 p-6 px-28 my-8 font-serif ${isAnimating? 'animate-slideInRight':''}`}>
      <p className="text-[#524c4c] leading-9">At Minitronix, accessible from<mark>www.minitronix.org</mark> , one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Minitronix and how we use it.</p>
      
      <h2 className="max-[600px]:text-3xl text-4xl font-semibold my-5 text-[#2e2b2b]">Consent</h2>
      <p className="text-[#524c4c] leading-9">By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
      
      <h2 className="max-[600px]:text-3xl text-4xl font-semibold my-5 text-[#2e2b2b]">Information we collect</h2>
      <p className="text-[#524c4c] leading-9">The personal information you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
      
      <h2 className="max-[600px]:text-3xl text-4xl font-semibold my-5 text-[#2e2b2b]">How we use your information</h2>
      <ul className="list-disc list-inside text-[#524c4c] leading-9">
        <li>Provide, operate, and maintain our website</li>
        <li>Improve, personalize, and expand our website</li>
        <li>Understand and analyze how you use our website</li>
        <li>Develop new products, services, and features</li>
        <li>Communicate with you</li>
        <li>Send you emails</li>
        <li>Prevent fraud</li>
      </ul>

      <h2 className="max-[600px]:text-3xl text-4xl font-semibold my-5 text-[#2e2b2b]">Log Files</h2>
      <p className="text-[#524c4c] leading-9">Minitronix follows a standard procedure of using log files. These files log visitors when they visit websites...</p>
      {/* You can add more content here as needed */}
    </div>
  );
};

export default PrivacyPolicy;
