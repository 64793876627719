import React, { useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { useState } from 'react';
import { MdOutlineMail } from "react-icons/md";
import { FaFacebookF, FaTiktok, FaLinkedin, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa6";
import { HiOutlineViewList } from "react-icons/hi";
import { CiCircleRemove } from "react-icons/ci";

import { useUser } from '../Authentication/UserContext';


import LogoPic from '../../Images/logo.png'
import PakImgLogo from '../../Images/PakImgLogo.png'

const Header = () => {
  const { role } = useUser();

  const navigate = useNavigate();
  const [toggle, settoggle] = useState(true);

  const linkClick = () => {
    settoggle(true);
    window.scrollTo(0, 0);
  }
  const open = (link) => {
    window.open(link, '_blank');
  }
  useEffect(() => {
    console.log(window.outerWidth);

    window.scrollBy(0, 0);

  }, [window.outerWidth, toggle])

  return (
    <header className=" min-[426px]:h-[53px] min-[770px]:h-[35px]   ">
      
      <div className='min-[1080px]:px-[100px]  min-w-full flex flex-row  justify-between fixed top-0 z-50 bg-white shadow h-[80px]   '>
        <img className={toggle ? 'w-44 h-16  hover:cursor-pointer min-[426px]:w-56 min-[426px]:h-16 ' : 'w-0 h-0 '} onClick={() => { navigate('/') }} src={LogoPic} alt='logopic' />
        <nav className={toggle ? ' h-24  hidden min-[426px]:flex min-[426px]:h-20 ' : ' flex flex-col min-h-[450px]  w-[100%] bg-white   pr-[30px] relative z-10 animate-slideInRight '}>
          <Link
            className=" min-[426px]:w-16 min-[426px]:text-[10px] min-[426px]:ml-[3px] min-[770px]:mr-[10px] min-[770px]:w-24 relative h-[80%]  justify-center items-center flex ml-8 border-t-8 border-solid border-red-500 rounded-md min-[770px]:text-[14px] opacity-90 uppercase overflow-hidden hover:text-white group" to="/" onClick={linkClick} >Home
            <span className="absolute inset-0 bg-red-500 scale-y-0 group-hover:scale-y-100 transition-transform duration-500 origin-top z-[-1]"></span>
          </Link>

          <CiCircleRemove className='min-[426px]:hidden text-3xl absolute top-0 right-0 ' onClick={() => { toggle ? settoggle(false) : settoggle(true) }} />

          <Link
            className=" min-[426px]:w-16 min-[426px]:text-[10px] min-[426px]:ml-[3px] relative h-[80%] min-[770px]:w-24 justify-center items-center flex ml-8 border-t-8 border-solid border-orange-500 rounded-md min-[770px]:text-[14px] opacity-90 uppercase overflow-hidden hover:text-white group" to="/product" onClick={linkClick} >Product
            <span className="absolute inset-0 bg-orange-500 scale-y-0 group-hover:scale-y-100 transition-transform duration-500 origin-top z-[-1]"></span>
          </Link>
          <Link
            className=" min-[426px]:w-16 min-[426px]:text-[10px] min-[426px]:ml-[3px] relative h-[80%] min-[770px]:w-24 justify-center items-center flex ml-8 border-t-8 border-solid border-green-500 rounded-md min-[770px]:text-[14px] opacity-90 uppercase overflow-hidden hover:text-white group" to="/about" onClick={linkClick} >About US
            <span className="absolute inset-0 bg-green-500 scale-y-0 group-hover:scale-y-100 transition-transform duration-500 origin-top z-[-1]"></span>
          </Link>
          <Link
            className=" min-[426px]:w-16 min-[426px]:text-[10px] min-[426px]:ml-[3px] relative h-[80%] min-[770px]:w-24 justify-center items-center flex ml-8 border-t-8 border-solid border-purple-500 rounded-md min-[770px]:text-[14px] opacity-90 uppercase overflow-hidden hover:text-white group" to="/contact" onClick={linkClick} >Contact
            <span className="absolute inset-0 bg-purple-500 scale-y-0 group-hover:scale-y-100 transition-transform duration-500 origin-top z-[-1]"></span>
          </Link>

          {
            role === "admin" ?
              <>
                <Link
                  className=" min-[426px]:w-16 min-[426px]:text-[10px] min-[426px]:ml-[3px] relative h-[80%] min-[770px]:w-24 justify-center items-center flex ml-8 border-t-8 border-solid border-indigo-500 rounded-md min-[770px]:text-[14px] opacity-90 uppercase overflow-hidden hover:text-white hover:cursor-pointer  group" to='/admin'>Admin
                  <span className="absolute inset-0 bg-indigo-500 scale-y-0 group-hover:scale-y-100 transition-transform duration-500 origin-top z-[-1]"></span>
                </Link>
                <Link
                  className=" min-[426px]:w-16 min-[426px]:text-[10px] min-[426px]:ml-[3px] relative h-[80%] min-[770px]:w-24 justify-center items-center flex ml-8 border-t-8 border-solid border-fuchsia-500 rounded-md min-[770px]:text-[14px] opacity-90 uppercase overflow-hidden hover:text-white group" to="/logout" onClick={linkClick} >LogOut
                  <span className="absolute inset-0 bg-fuchsia-500 scale-y-0 group-hover:scale-y-100 transition-transform duration-500 origin-top z-[-1]"></span>
                </Link>

              </> :
              <><Link
                className=" min-[426px]:w-16 min-[426px]:text-[10px] min-[426px]:ml-[3px] relative h-[80%] min-[770px]:w-24 justify-center items-center flex ml-8 border-t-8 border-solid border-fuchsia-500 rounded-md min-[770px]:text-[14px] opacity-90 uppercase overflow-hidden hover:text-white group" to="/login" onClick={linkClick} >LogIn
                <span className="absolute inset-0 bg-fuchsia-500 scale-y-0 group-hover:scale-y-100 transition-transform duration-500 origin-top z-[-1]"></span>
              </Link>

                <a
                  className=" min-[426px]:w-16 min-[426px]:text-[10px] min-[426px]:ml-[3px] relative h-[80%] min-[770px]:w-24 justify-center items-center flex ml-8 border-t-8 border-solid border-indigo-500 rounded-md min-[770px]:text-[14px] opacity-90 uppercase overflow-hidden hover:text-white hover:cursor-not-allowed  group"><img className='min-[426px]:h-[70px] min-[768px]:w-[70px] w-[100px] h-[100px]' src={PakImgLogo} alt='Pak Img Logo' />
                  <span className="absolute inset-0 bg-indigo-500 scale-y-0 group-hover:scale-y-100 transition-transform duration-500 origin-top z-[-1]"></span>
                </a>
              </>
          }


        </nav>
        <HiOutlineViewList className={` min-[450px]:hidden ${toggle ? 'flex text-3xl mt-[20px]' : 'hidden'}`} onClick={() => { toggle ? settoggle(false) : settoggle(true) }} />
      </div>
      <div className='hidden min-[770px]:flex min-[770px]:min-w-max min-[770px]:justify-around px-32 mt-[80px] bg-[#073B4C] min-h-[35px] items-center text-white '>

        <div className='opacity-90 flex flex-row space-x-2'>
          <MdOutlineMail className='text-yellow-300 text-xl cursor-pointer ' onClick={() => open('mailto:minitronixs@gmail.com')} />
          <a className='cursor-pointer' onClick={() => open('mailto:minitronixs@gmail.com')}>minitronixs@gmail.com</a>
        </div>
        <div className='opacity-90 flex flex-row'>
          <FaWhatsapp className='text-yellow-300 text-xl cursor-pointer' onClick={() => open('https://wa.me/923455174586')} />
          <a className='cursor-pointer' onClick={() => open('https://wa.me/923455174586')}>+92 345 5174586</a>
        </div>
        <div className='flex flex-row  space-x-6 text-base'>

          <FaFacebookF className='opacity-70 hover:cursor-pointer hover:opacity-100 transition-all text-xl' onClick={() => open('https://www.facebook.com/people/Minitonix/61565706076399/?mibextid=LQQJ4d')} />
          <FaTiktok className='opacity-70 hover:cursor-pointer hover:opacity-100 transition-all text-xl' onClick={() => open('https://www.tiktok.com/@minitronixs?lang=en')} />
          <FaYoutube className='opacity-70 hover:cursor-pointer hover:opacity-100 transition-all text-xl' onClick={() => open('https://www.youtube.com/@minitronixs')} />
          <FaInstagram className='opacity-70 hover:cursor-pointer hover:opacity-100 transition-all text-xl' onClick={() => open('https://www.instagram.com/minitronixs/?utm_source=ig_web_button_share_sheet')} />
          <FaLinkedin className='opacity-70 hover:cursor-pointer hover:opacity-100 transition-all text-xl' onClick={() => open('https://www.linkedin.com/in/minitronix-minitronix-13510432a/')} />
        </div>
      </div>
    </header>
  )
}

export default Header